import { h } from "preact";
import useCards from "../../../hooks/useCards";

import style from "./style.css";
import { Link } from "preact-router";
import ArrowIcon from "../../../components/icons/ArrowIcon";
import Card from "../../../components/card";

const S1JWeb = () => {
  const cards = useCards();

  return (
    <div class={`${style.project} ${style.S1JWeb}`}>
      <div class={style.header}>
        <div class={style.left}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
            alt="SquareOneJobs Logo"
          />
          <h1>SquareOneJobs Web</h1>
          <p>Designer & Developer | 2019-2022</p>
        </div>
        <img
          class={style.laptop}
          src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/edashboard.png"
          alt="SquareOneJobs web app employer screen"
        />
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>COMPANY</p>
              <p class={style.info}>SquareOneJobs</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>POSITION</p>
              <p class={style.info}>Co-founder/CEO</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>2019-2022</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Web Designer & Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>Figma</p>
              <p class={style.info}>HTML</p>
              <p class={style.info}>CSS</p>
              <p class={style.info}>Javascript</p>
              <p class={style.info}>React</p>
              <p class={style.info}>StripeJS</p>
              <p class={style.info}>Google OAuth 2.0</p>
              <p class={style.info}>Google Analytics</p>
              <p class={style.info}>AWS Amplify</p>
              <p class={style.info}>Github</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>The Problem</h2>
        <p>
          Societal hurdles prevent people with barriers to employment (i.e.,
          criminal record, veteran, disibility, etc.) from finding stable,
          full-time employment they enjoy.
        </p>
        <p>
          <i>
            This is not the only app for this project! Check out the{" "}
            <Link href="/work/squareonejobs-mobile">mobile app</Link> and{" "}
            <Link href="/work/squareonejobs-backend">backend</Link> reports.
          </i>
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Solution</h2>
        <p>
          An online job service that builds meaningful connections using the gig
          economy to build user credibility, a smart hiring piepline so
          employers are never understaffed, and a.i. to match employers with the
          perfect candidate.
        </p>
        <p>
          Having two groups of users (job-seekers and employers) required
          creating two separate dashboards to best suit the user’s needs.
        </p>
      </div>
      <div
        class={`${style.section} ${style.sectionLg} ${style.sectionSplit} ${style.users}`}
      >
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/userportfolioweb.png"
          alt="SquareOneJobs web app user portfolio page"
        />
        <div>
          <h2>The Users</h2>
          <p>
            From the creation of our startup, our goal was to help job-seekers
            find purpose-driven employment. To do this, we wanted to provide all
            the tools they need to set them up for success, which included:
          </p>
          <ul>
            <li>
              A portfolio where they can enter eduction, previous work
              experience, and upload documents such as a resume or cover letter
            </li>
            <li>
              Not only having full and part-time jobs, but also gig jobs where
              users can earn references to help with future positions
            </li>
            <li>An easy, 1-click solution to apply to jobs</li>
          </ul>
        </div>
      </div>
      <div
        class={`${style.section} ${style.sectionLg} ${style.sectionSplit} ${style.employers}`}
      >
        <div>
          <h2>The Employers</h2>
          <p>
            As the employers are our customers, our biggest priority was to get
            them to post a job, and to follow through and help them choose the
            perfect candidate. To do this, we simplified our dashboard
            experience to give them all the info they need, including:
          </p>
          <ul>
            <li>
              A header which holds statistics of all their current listings,
              such as: the number of current active listings, the number of
              applicants, and listing views
            </li>
            <li>
              A card to display important information about any new applicants
              that have just applied{" "}
            </li>
            <li>
              A list to remind employers of any upcoming gig jobs they have
              posted, reminding them who was hired and when the job is.
            </li>
          </ul>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/edashboard.png"
          alt="SquareOneJobs web app employer dashboard page"
        />
      </div>
      <div class={style.bgDark}>
        <div
          class={`${style.section} ${style.sectionMd} ${style.S1JWebTheRedesign}`}
        >
          <div class={style.flex}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/userdashboardold.png"
              alt="SquareOneJobs web app old dashboard screen"
            />
            <ArrowIcon />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/userdashboard.png"
              alt="SquareOneJobs web app new dashboard screen"
            />
          </div>
          <div>
            <h2>The Redesign</h2>
            <p>
              After reconfiguring our brand and designing the mobile app in
              early 2021, we needed to keep consistency across all platforms.
              Thus, a redesign was necessary for the website.
            </p>
            <p>
              We got a new font, changed our color palette, rounded some
              corners, and added a dark theme to make the user experience much
              more enjoyable.
            </p>
            <p>
              One of the biggest changes in the user dashboard was decluttering
              the amount of information shown. The old dashboard (first image)
              has too many cards, making it difficult to see what the most
              import piece of information we want the user to consume is. The
              redesigned dashboard (second image) provides a simplier, cleaner
              view, with recommended jobs front and center.
            </p>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status</h2>
        <p>
          The SquareOneJobs Web app was released in July 2021. Minor updates
          will still be released over the next few months.
        </p>
      </div>
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards
                  .map((c) => c.projTitle)
                  .indexOf("SquareOneJobs Mobile App")
              ]
            }
          />
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Backend")
              ]
            }
          />
        </div>
      </div>
    </div>
  );
};
export default S1JWeb;
