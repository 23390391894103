import { h } from "preact";
import { Route, Router } from "preact-router";
import { useEffect, useState } from "preact/hooks";

import Navbar from "./navbar";

import Home from "../routes/home";
import { Theme } from "../hooks/useTheme";
import { Path } from "../hooks/usePath";
import About from "../routes/about";
import Work from "../routes/work";
import S1JMobile from "../routes/work/subroutes/S1JMobile";
import Footer from "./footer";
import S1JWeb from "../routes/work/subroutes/S1JWeb";
import S1JBackend from "../routes/work/subroutes/S1JBackend";
import TraQ from "../routes/work/subroutes/TraQ";
import UpArrow from "./uparrow";
import CCFW from "../routes/work/subroutes/CCFW";
import BritNateWedding from "../routes/work/subroutes/BritNateWedding";
import ThriveFitness from "../routes/work/thrive-fitness";
import SquareOneJobs from "../routes/work/squareonejobs";
import Loader from "./loader";
import useImagesLoaded from "../hooks/useImagesLoaded";
import Moviegoers from "../routes/work/moviegoers";
import Domov from "../routes/work/domov";

const firebaseConfig = {
  apiKey: "AIzaSyAiCX_rNcBVf4aeuqKMf5sR6NWB26MWYcI",
  authDomain: "portfolio-e36b7.firebaseapp.com",
  projectId: "portfolio-e36b7",
  storageBucket: "portfolio-e36b7.appspot.com",
  messagingSenderId: "540940469226",
  appId: "1:540940469226:web:ac00330954da0ada86e893",
  measurementId: "G-9W0M7BL6E1",
};

const App = () => {
  const [isDark, setIsDark] = useState(false);
  const [isNavDrawerOpen, setisNavDrawerOpen] = useState(false);
  const [path, setPath] = useState(
    typeof window !== "undefined" ? window.location.pathname : "/"
  );
  const { isLoaded, setIsLoaded } = useImagesLoaded();

  const handleRouteChange = (e) => {
    setIsLoaded(false);
    setPath(e.url);
    if (typeof window !== "undefined") window.scrollTo(0, 0);
  };

  useEffect(() => {
    gtag("event", "custom_page_view", { value: path });
  }, [path]);

  return (
    <Theme.Provider value={{ isDark, setIsDark }}>
      <Path.Provider value={{ path }}>
        <div
          id="app"
          class={`${isDark ? "dark" : ""} ${
            isNavDrawerOpen ? "navDrawerOpen" : ""
          }`}
        >
          <Navbar
            isDrawerOpen={isNavDrawerOpen}
            setIsDrawerOpen={setisNavDrawerOpen}
            isHome={path === "/"}
          />
          <Loader isActive={!isLoaded} />
          <Router onChange={handleRouteChange}>
            <Route path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/work" component={Work} />
            <Route path="/work/squareonejobs-mobile" component={S1JMobile} />
            <Route path="/work/squareonejobs-web" component={S1JWeb} />
            <Route path="/work/squareonejobs-backend" component={S1JBackend} />
            <Route path="/work/traq" component={TraQ} />
            <Route path="/work/ccfw" component={CCFW} />
            <Route path="/work/brit-nate-wedding" component={BritNateWedding} />
            <Route path="/work/thrive-fitness" component={ThriveFitness} />
            <Route path="/work/squareonejobs" component={SquareOneJobs} />
            <Route path="/work/moviegoers" component={Moviegoers} />
            <Route path="/work/domov" component={Domov} />
          </Router>
          <Footer />
          <UpArrow />
        </div>
      </Path.Provider>
    </Theme.Provider>
  );
};

export default App;
