import { useEffect, useState } from "preact/hooks";

const useImagesLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let imgs = document.images,
      len = imgs.length,
      counter = 0;

    [].forEach.call(imgs, (img) => {
      if (img.complete) incrementCounter();
      else img.addEventListener("load", incrementCounter, false);
    });

    function incrementCounter() {
      counter++;
      if (counter === len) {
        setIsLoaded(true);
      }
    }
  });

  return { isLoaded, setIsLoaded };
};
export default useImagesLoaded;
