import { h } from "preact";
import Card from "../../../components/card";
import useCards from "../../../hooks/useCards";
import style from "./style.css";

import { useTheme } from "../../../hooks/useTheme";

const TraQ = () => {
  const cards = useCards();
  const { isDark } = useTheme();

  return (
    <div class={`${style.project} ${style.traq}`}>
      <div class={`${style.headerCenter}`}>
        <img
          src={
            isDark
              ? "https://nr-portfolio.s3.amazonaws.com/traq/traqlight.png"
              : "https://nr-portfolio.s3.amazonaws.com/traq/traqdark.png"
          }
          alt="TraQ Logo"
        />
        <h1>TraQ Backend</h1>
        <p>Lead Backend Developer | Spring 2021</p>
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>CLASS</p>
              <p class={style.info}>CPE-322 (Stevens)</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Lead Backend Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>Spring 2021</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>NodeJS</p>
              <p class={style.info}>ExpressJS</p>
              <p class={style.info}>MongoDB</p>
              <p class={style.info}>Mongoose</p>
              <p class={style.info}>Postman</p>
              <p class={style.info}>Heroku</p>
              <p class={style.info}>GitHub</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>The Problem</h2>
        <p>
          The world is suffering from a global pandemic and despite efforts,
          COVID continues to spread. It’s hard for people to keep up to date
          with the status’ of how their family and friends are doing with COVID.
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Solution</h2>
        <p>
          Design & create a mobile app that allows users to not only update
          their status with COVID, but track their close family members and
          friends. Users will be able to create an account, update their status,
          import vaccination card information, and see non-personal information
          about other users in their friends list.{" "}
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <iframe src="https://drive.google.com/file/d/1dZxx5i2RbfwVunZZX2seJNSjCZvF9EgE/preview" />
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Approach</h2>
        <p>
          As the frontend was a React Native mobile app, we decided to use a
          NodeJS server on the backend. I was the only backend developer, so I
          had the flexibility to structure the project and use the node modules
          that I saw fit best.{" "}
        </p>
      </div>
      <div class={style.bgDark}>
        <div
          class={`${style.section} ${style.sectionLg} ${style.TraQToolsUsed}`}
        >
          <div class={style.toolsContainer}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/nodejslogo.png"
              alt="NodeJS logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/expresslogo.png"
              alt="ExpressJS logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/mongodblogo.png"
              alt="MongoDB logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/traq/herokulogo.png"
              alt="Heroku logo"
            />
          </div>
          <div>
            <h2>Tools Used</h2>
            <p>
              This project didn’t require too much, as the scope of the project
              was not that large. Using NodeJS as the language and ExpressJS as
              our framework, we created 10 API endpoints for the frontend to
              talk to.
            </p>
            <p>
              To store users, we used a MongoDB database and the Mongoose npm
              module.{" "}
            </p>
            <p>Lastly, the project was hosted on a Heroku server. </p>
          </div>
        </div>
      </div>
      <div
        class={`${style.section} ${style.sectionLg} ${style.TraQCommunicating}`}
      >
        <div>
          <h2>Communicating with Frontend Devs</h2>
          <p>
            When first starting, it was exciting to work on a project where I
            was only responsible for the backend. Because of this, I was able to
            spend more time on optimizing the endpoints to be as efficient as
            possible.
          </p>
          <p>
            It was also a great learning experience in communication with the
            frontend devs. Like some of my other proejcts, I created an API
            Chart (user APIs shown in image) that explains the type of endpoint,
            route, if it requires an auth token, and the description of what the
            API returns.
          </p>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/traq/apichart.png"
          alt="API Chart for backend routes"
        />
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status & Conclusions</h2>
        <p>
          The TraQ app frontend and backend were successfully created fully
          functional as per the plan. Unfortunately, TraQ was created as a
          school project, and also died as a school project.{" "}
        </p>
        <p>
          We did have talks of turning the app into a “digital medical record”
          down the line, where you can keep all of your medical records in one
          place. But I guess that’s a project for another time...
        </p>
      </div>
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Backend")
              ]
            }
          />
        </div>
      </div>
    </div>
  );
};
export default TraQ;
