import { h } from "preact";

const GroupIcon = (props) => (
  <svg
    height={512}
    viewBox="0 0 32 32"
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 3"
    {...props}
  >
    <path d="M11.429 16a5.715 5.715 0 1 0-5.715-5.714A5.72 5.72 0 0 0 11.429 16zM18.905 20.721A10.434 10.434 0 0 0 1 28a1 1 0 0 0 1 1h18.86a1 1 0 0 0 1-1 10.067 10.067 0 0 0-.485-3.124 10.36 10.36 0 0 0-2.47-4.155z" />
    <circle cx={23.5} cy={12.25} r={4.25} />
    <path d="M23.5 17.67a7.482 7.482 0 0 0-3.806 1.057c.217.194.436.385.641.595a12.388 12.388 0 0 1 2.952 4.966 11.488 11.488 0 0 1 .437 1.882H30a1 1 0 0 0 1-1 7.508 7.508 0 0 0-7.5-7.5z" />
  </svg>
);

export default GroupIcon;
