import { h } from "preact";
import { useRef } from "preact/hooks";
import Navbar from "../../components/home-navbar";
import Header from "./sections/Header";
import Work from "./sections/Work";
import style from "./style.css";

const Home = () => {
  const workRef = useRef(null);

  return (
    <div class={style.home}>
      <Navbar />
      <Header workRef={workRef} />
      <Work workRef={workRef} />
    </div>
  );
};

export default Home;
