import { h } from "preact";
import style from "./style.css";

const Button = ({
  active = true,
  color = "purple",
  link = false,
  onClick = () => true,
  text = "",
}) => {
  const renderButtonText = (
    <div class={style.inner}>
      <p class={style.buttonText}>{text}</p>
    </div>
  );
  const containerClass = `${style.button} ${active ? style.active : ""} ${style[color]}`;

  if (link)
    return (
      <a
        data-testid="button-link"
        href={link}
        target="_blank"
        rel="noreferrer"
        class={containerClass}
        onClick={onClick}
      >
        {renderButtonText}
      </a>
    );
  return (
    <button data-testid="button-div" class={containerClass} onClick={onClick}>
      {renderButtonText}
    </button>
  );
};
export default Button;
