import { h } from "preact";
const EmailIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.07 3.32H2.93A2.933 2.933 0 0 0 0 6.25v12.5a2.933 2.933 0 0 0 2.93 2.93h19.14A2.933 2.933 0 0 0 25 18.75V6.25a2.933 2.933 0 0 0-2.93-2.93Zm.977 15.43a.978.978 0 0 1-.977.977H2.93a.978.978 0 0 1-.977-.977V6.25c0-.538.438-.977.977-.977h19.14c.539 0 .977.439.977.977v12.5Z"
      fill="#fff"
    />
    <path
      d="M22.881 4.538 12.5 12.259 2.119 4.538.953 6.105 12.5 14.694l11.547-8.589-1.166-1.567Z"
      fill="#fff"
    />
  </svg>
);

export default EmailIcon;
