import { h } from "preact";
import style from "./style.css";
import Card from "../../../components/card";
import useCards from "../../../hooks/useCards";

const BritNateWedding = () => {
  const cards = useCards();

  return (
    <div class={`${style.project} ${style.BNWedding}`}>
      <div class={style.header}>
        <div class={style.left}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/logo.png"
            alt="Brit & Nate Wedding Logo"
          />
          <h1>Brit & Nate Wedding</h1>
          <p>Designer & Developer | January 2022</p>
        </div>
        <img
          class={style.laptop}
          src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/header-laptop.png"
          alt="Brit & Nate Wedding Website Main Page"
        />
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Designer & Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>January 2022</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>Figma</p>
              <p class={style.info}>ReactJS</p>
              <p class={style.info}>HTML</p>
              <p class={style.info}>CSS</p>
              <p class={style.info}>Javascript</p>
              <p class={style.info}>Firebase Hosting</p>
              <p class={style.info}>Firebase Cloud Functions</p>
              <p class={style.info}>Responsive Web Design</p>
              <p class={style.info}>Github</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>Introduction</h2>
        <p>
          While wedding planning, I was given many tips from my relatives and
          colleagues of which wedding website to choose a template from. I
          looked through some of the templates and thought, “well that could
          work but...” I’m a designer & a developer - I have to do it myself.
          So, I present to you: our wedding website!
        </p>
        <p>
          A few pages that were a must: password, home, details, photos, and of
          course, the registry. For privacy reasons, I’ve changed some wedding
          details, but other than that what you see is what’s live!
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Design</h2>
        <p>
          Comparing it to other wedding sites, I know we wanted something
          classy, but personable. My Fiancée loves cursive fonts, so that was a
          must. Our wedding colors are peach and navy, so navy was a go. I
          wrapped it all up with a marble background, and it’s off to the races.
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.imgSection}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/stylesheet.png"
          alt="Colors & Fonts used on this website"
        />
        <p>Colors & fonts used</p>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.imgSection}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/header.png"
          alt="Header page on Laptop & Mobile"
        />
        <p>Home page</p>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.imgSection}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/password.png"
          alt="Password page on Laptop & Mobile"
        />
        <p>Password page</p>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.imgSection}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/rsvp.png"
          alt="RSVP page on Laptop & Mobile"
        />
        <p>RSVP page</p>
      </div>
      <div class={style.bgDark}>
        <div
          class={`${style.section} ${style.sectionMd} ${style.BNWeddingFirebase}`}
        >
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/firebase-logo.png"
            alt="Firebase Cloud Functions Logo"
          />
          <div>
            <h2>Firebase with Sheets API</h2>
            <p>
              I wanted the RSVP form data to be added to a Google Sheet so I can
              make it simple for my Fiancée to view our guests, and don’t have
              to teach her how to use the Firebase console. Luckily, there’s a
              Google Sheets API that you can implement inside of Firebase
              Functions, so whenever new data is added to the database (when an
              RSVP form is submitted), it can automatically update a
              spreadsheet. Problem solved!
            </p>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status</h2>
        <p>
          The website is currently live, however it’s password protected for
          security reasons. We’re excited to be able to customize our wedding
          website to match it with the rest of the wedding experience!
        </p>
      </div>
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Web App")
              ]
            }
          />
          <Card card={cards[cards.map((c) => c.projTitle).indexOf("CCFW")]} />
        </div>
      </div>
    </div>
  );
};
export default BritNateWedding;
