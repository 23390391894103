import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Link } from "preact-router/match";
import style from "./style.css";
import EmailIcon from "../icons/EmailIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import { usePath } from "../../hooks/usePath";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import ThemeSwitch from "../../components/theme-switch";

const Navbar = ({
  isHome = false,
  isDrawerOpen = false,
  setIsDrawerOpen = () => true,
}) => {
  const { width } = useWindowDimensions();
  const { path } = usePath();
  const [isHidden, setIsHidden] = useState(isHome);
  const [prevPos, setPrevPos] = useState(
    typeof window !== "undefined" ? window.pageYOffset : 0
  );
  const [isAtTop, setIsAtTop] = useState(
    typeof window !== "undefined" && window.pageYOffset === 0
  );

  useEffect(() => {
    setIsHidden(isHome);
  }, [isHome]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    const currPos = typeof window !== "undefined" ? window.pageYOffset : 0;

    if (isHome && currPos < (width / 2) * 0.7) {
      setIsHidden(true);
    } else {
      prevPos > currPos ? setIsHidden(false) : setIsHidden(true);
    }
    setPrevPos(currPos);
    setIsAtTop(currPos <= 0);
  };

  const getPageClass = () => {
    return style[path.slice(path.lastIndexOf("/") + 1)];
  };

  const routes = (
    <nav>
      <Link
        tabIndex={isHome ? "-1" : null}
        href="/work"
        class={path === "/work" ? style.active : ""}
        onClick={() => setIsDrawerOpen(false)}
      >
        work
      </Link>
      <Link
        tabIndex={isHome ? "-1" : null}
        href="/about"
        class={path === "/about" ? style.active : ""}
        onClick={() => setIsDrawerOpen(false)}
      >
        about
      </Link>
      <div class={style.socials}>
        <a
          tabIndex={isHome ? "-1" : null}
          href="mailto:nathanrenner00@gmail.com"
        >
          <EmailIcon />
        </a>
        <a
          tabIndex={isHome ? "-1" : null}
          href="https://www.linkedin.com/in/nathanrenner/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon />
        </a>
      </div>
      <ThemeSwitch />
    </nav>
  );

  return (
    <div
      class={`${style.navbar} ${isDrawerOpen ? style.open : ""} ${
        isHidden ? style.hidden : ""
      } ${isAtTop ? style.atTop : ""} ${getPageClass()}`}
    >
      <div class={style.body}>
        <Link
          href="/"
          class={style.logo}
          onClick={() => setIsDrawerOpen(false)}
        >
          <img alt="Nate's logo. An N R" />
        </Link>
        <div
          class={style.hamburger}
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
        {routes}
      </div>
      <div class={style.drawer}>{routes}</div>
    </div>
  );
};
export default Navbar;
