import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";
import { useTheme } from "../../hooks/useTheme";
import LogoDark from "../../assets/logo-dark.png";
import LogoLight from "../../assets/logo-light.png";
import EmailIcon from "../icons/EmailIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import ThemeSwitch from "../theme-switch";

const Navbar = () => {
  const { isDark } = useTheme();

  return (
    <header class={style.navbarHome}>
      <div class={style.body}>
        <Link href="/" class={style.logo}>
          <img src={isDark ? LogoDark : LogoLight} alt="Nate's logo. An N R" />
        </Link>
        <nav>
          <Link href="/work">work</Link>
          <Link href="/about">about</Link>
          <div class={style.socials}>
            <a href="mailto:nathanrenner00@gmail.com">
              <EmailIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/nathanrenner/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
          </div>
          <ThemeSwitch />
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
