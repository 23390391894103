import { h } from "preact";

const PaperIcon = (props) => (
  <svg
    height={512}
    viewBox="0 0 496 496"
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M347.57 80H62.75C52.41 80 44 88.41 44 98.75v378.5C44 487.59 52.41 496 62.75 496h284.82c10.33 0 18.74-8.41 18.74-18.75V98.75c0-10.34-8.41-18.75-18.74-18.75zM284 344H132c-8.84 0-16-7.16-16-16s7.16-16 16-16h152c8.84 0 16 7.16 16 16s-7.16 16-16 16zm0-64H132c-8.84 0-16-7.16-16-16s7.16-16 16-16h152c8.84 0 16 7.16 16 16s-7.16 16-16 16zm0-64H132c-8.84 0-16-7.16-16-16s7.16-16 16-16h152c8.84 0 16 7.16 16 16s-7.16 16-16 16z" />
    <path d="M452 48v352c0 24.16-19.441 48-53.69 48V98.75c0-27.99-22.76-50.75-50.74-50.75H97.69c0-26.47 22.81-48 50.84-48h252.63C429.19 0 452 21.53 452 48z" />
  </svg>
);

export default PaperIcon;
