import { h } from "preact";
import { useRef } from "preact/hooks";
import style from "../style.css";
import Card from "../../../components/card";
import { Link } from "preact-router";
import useOnScreen from "../../../hooks/useOnScreen";
import useCards from "../../../hooks/useCards";

const Work = ({ workRef }) => {
  const linkRef = useRef(null);
  const linkVisible = useOnScreen(linkRef);
  const cardsRef = useRef(null);
  const cardsVisible = useOnScreen(cardsRef);
  const cards = useCards();

  return (
    <div class={style.work} ref={workRef}>
      <div
        class={`${style.cardsContainer} ${
          cardsVisible ? style.cardsVisible : ""
        }`}
        ref={cardsRef}
      >
        {cards.slice(0, 4).map((card, i) => (
          <Card card={card} key={i} />
        ))}
      </div>
      <div class={style.linkContainer} ref={linkRef}>
        <Link href="/work" class={`${linkVisible ? style.visible : ""}`}>
          see all work
        </Link>
      </div>
    </div>
  );
};

export default Work;
