import { h } from "preact";
import style from "./style.css";
import gs from "../general.css";
import Button from "../../../components/button";
import DevicesIcon from "../../../components/icons/DevicesIcon";
import StarIcon from "../../../components/icons/StarIcon";
import QuestionIcon from "../../../components/icons/QuestionIcon";
import PrototypeIcon from "../../../components/icons/PrototypeIcon";

const SquareOneJobs = () => {
  return (
    <div class={`${gs.project} ${style.squareonejobs}`}>
      <img
        class={gs.hero}
        src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/hero.jpg"
        alt="Banner of SquareOneJobs Logo & Mobile Phones"
      />
      <div class={`${gs.projectDetailsSection} ${style.projectDetailsSection}`}>
        <div class={`${gs.projectDetailsInner} ${style.projectDetailsInner}`}>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>ROLE</h3>
            <h2>Co-founder/UX Designer</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>COMPANY</h3>
            <h2>SquareOneJobs</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>TIMEFRAME</h3>
            <h2>April 2019-Present</h2>
          </div>
          <Button
            color="squareOneJobsGreen"
            link="https://drive.google.com/file/d/1BJag6I7DEQmcP01Luc476fyhEpw9lr3k/view?usp=sharing"
            text="PITCH DECK"
            active={false}
          />
        </div>
      </div>
      <div class={gs.toolsUsedSection}>
        <h3>TOOLS & CONCEPTS</h3>
        <h2>Figma · Adobe XD • Mobile Design · Responsive Web Design</h2>
        <h3>TEAM</h3>
        <h2>Nate Renner • Greg LaVersa</h2>
      </div>
      <div class={`${gs.section} ${style.projectOverviewSection}`}>
        <div
          class={`${gs.headerWithIcon} ${style.headerWithIcon} ${style.description}`}
        >
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <DevicesIcon />
          </div>
          <div>
            <h3>PRODUCT DESCRIPTION</h3>
            <h2>
              SquareOneJobs is a web and mobile job service that builds
              meaningful connections using the gig economy to establish user
              credibility, a smart hiring pipeline so employers are never
              understaffed, and a.i. to match employers with the perfect
              candidate.
            </h2>
            <h2>
              This is not the only showcase for this project! Check out the{" "}
              <a href="/work/squareonejobs-mobile">mobile</a>,{" "}
              <a href="/work/squareonejobs-web">web</a>, and{" "}
              <a href="/work/squareonejobs-backend">backend</a> development
              pages.
            </h2>
          </div>
        </div>
        <div class={style.splitContainer}>
          <div class={style.imgContainer}>
            <img
              class={style.phone}
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/listing-mobile.jpg"
              alt="SquareOneJobs Mobile App listing page"
            />
          </div>
          <div class={style.right}>
            <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
              <div class={`${gs.iconContainer} ${style.iconContainer}`}>
                <QuestionIcon />
              </div>
              <div>
                <h3>PROBLEM</h3>
                <h2>
                  Societal hurdles prevent people with barriers to employment
                  (i.e., criminal record, veteran, disability, etc.) from
                  finding stable, full-time employment they enjoy.
                </h2>
              </div>
            </div>
            <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
              <div class={`${gs.iconContainer} ${style.iconContainer}`}>
                <StarIcon />
              </div>
              <div>
                <h3>PROJECT GOAL</h3>
                <h2>
                  Design a mobile and responsive web application for job seekers
                  to connect with employers.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class={style.bgGreen}>
        <div class={`${gs.section} ${gs.section40} ${style.gettingStarted}`}>
          <h1>Getting Started</h1>
          <p>
            From SquareOneJob’s inception, we knew this wasn’t just another
            project — we were trying to combat a difficult and extremely
            important societal issue. We wanted to be relentless, and we wanted
            to do it right. So, what’s the first part of the design process?{" "}
            <b>Empathize</b>. As neither of us have been through or directly
            impacted by the US criminal justice system, we knew we had to take
            extra time empathizing with and understanding our users. We spent
            months volunteering at the Hoboken Homeless Shelter to chat with
            folks and hear their stories, walked around Hoboken speaking with
            those living on the streets, and even took our thoughts and
            questions to Reddit to see what other people have been through
            across the nation.
          </p>
          <p>
            <i>
              Note: I’ve made this project one of the highlighted pages of my
              portfolio because this has been a startup I’ve cared for deeply.
              Even though at the time we did not have proper training on the
              tradional design process, we did hit the key elements that are
              used to create something that users truly need and love. If you
              want to see another project that covers all the traditional steps
              of the design process, check out my work on{" "}
              <a href="/work/thrive-fitness">Thrive Fitness</a>.
            </i>
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.meetTheUsers}`}>
        <h1>Meet the Users</h1>
        <div class={style.splitSection}>
          <div class={style.persona}>
            <div class={style.personaHeader}>
              <img
                src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/Henry.jpg"
                alt="Avatar image of male with a beard"
              />
              <div>
                <h2>Persona 1: Henry</h2>
                <p>
                  A formerly incarcerated individual, who is looking to re-enter
                  the workforce but he’s had some trouble finding employment.
                </p>
              </div>
            </div>
            <h3>Who</h3>
            <p>
              Has experience in the field of computer science and is looking for
              fulfilling employment.
            </p>
            <h3>Wants</h3>
            <p>
              An easy way to find employment and show employers he’s worked hard
              to get to where he is.
            </p>
            <h3>Current Status</h3>
            <p>
              Has tried many online job services recommended by friends and
              family, but gets overlooked when employers find out about his
              record.
            </p>
          </div>
          <div class={style.persona}>
            <div class={style.personaHeader}>
              <img
                src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/Stephanie.jpg"
                alt="Avatar image of female professional"
              />
              <div>
                <h2>Persona 2: Stephanie</h2>
                <p>
                  Needs to hire trustworthy top talent that will remain loyal to
                  his company. She believes that second chances should always be
                  given to those who work for it.
                </p>
              </div>
            </div>
            <h3>Who</h3>
            <p>
              Owner of a small-medium sized business. Needs new full-time hires
              on a monthly basis and some extra hands to help with one-day jobs
              from time to time.
            </p>
            <h3>Wants</h3>
            <p>
              A fast, safe, and reliable way to find the best job candidates
              online.
            </p>
            <h3>Current Status</h3>
            <p>
              Uses other online job platforms, but constantly finds unmotivated
              individuals that don’t stay with the company for long.
            </p>
          </div>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Pain Points</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Navigation</h2>
            <p>
              Our target users have had trouble in the past navigating through
              interfaces they’re not familiar with
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Creating Profiles</h2>
            <p>
              When individuals don’t have proper training, it’s difficult for
              them to create professional, appropriate profiles for themselves
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Accessibility</h2>
            <p>
              Our target users can encompass a wide variety of people, and we
              want to be sure to address the needs of those with accessibility
              concerns
            </p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.competitionSection}`}>
        <div>
          <h1>Competition</h1>
          <p>
            Before diving in to brainstorming and designing, we knew we had to
            take a look at the competition — and there are a lot of other job
            services. Our research showed us that most other job services are a
            once-and-done transaction. An employer posts a job, job seekers
            apply to the job, and then the job is filled. There is a reason why
            this method works — it’s effective.
          </p>
        </div>
        <div>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/competition.jpg"
            alt="Logos for Indeed, LinkedIn, Monster Jobs, Google for Jobs, Glassdoor"
          />
        </div>
      </div>
      <div class={style.bgGreen}>
        <div class={`${gs.section} ${gs.section40} ${style.designPlan}`}>
          <h1>The Design Plan</h1>
          <p>
            What if there was a way to truly know the steps that your applicants
            took to get to where they are today? Not only seeing the major
            milestones, but if you could see every effort they made and heard
            their success stories from other credible sources, you’d get to know
            them even better before the interview. So we started brainstorming
            and asked the question,{" "}
          </p>
          <p>
            <i>“What would a more personable job service look like?”</i>
          </p>
          <p>
            After time passed and ideas were brainstormed, we had a plan: create
            a job service where individuals with criminal records (or anyone
            with societal barriers to employment) can complete gig jobs to build
            their credibility and earn references for their “micro-portfolio.”
            When they’re ready, they can start applying to full-time positions,
            having plenty of references to show future employers that they’re
            the right candidate to hire.
          </p>
          <p>Now that we had a plan, let’s design!</p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/sitemap.jpg"
          alt="SquareOneJobs sitemap"
        />
        <p class={gs.caption}>Sitemap</p>
      </div>
      <div class={`${gs.section} ${gs.section50}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/wireframe.jpg"
          alt="SquareOneJobs Mobile app paper wireframes"
        />
        <p class={gs.caption}>Mobile App Paper Wireframes</p>
      </div>
      <div class={`${gs.section} ${style.mockupSection}`}>
        <div class={`${gs.section60} ${style.headerContainer}`}>
          <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <DevicesIcon />
            </div>
            <div>
              <h1>Mobile & Web Mockups</h1>
              <p>
                After spending dozens hours meticulously crafting our ideas to
                life, we finally came up with a design that’s user-friendly,
                consistent across all devices, and demonstrates our company
                voice of being job seeker’s “helping hand” to finding
                employment.
              </p>
              <p>
                You might notice we didn’t include any digital wireframes in
                this project. I definitely learned my lesson to not skip over
                that step — it saves hours of time.
              </p>
            </div>
          </div>
        </div>
        <div class={style.phonesContainer}>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/home-mobile.jpg"
              alt="SquareOneJobs Mobile home screen"
            />
          </div>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/explore-mobile.jpg"
              alt="SquareOneJobs Mobile explore screen"
            />
          </div>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/listing-mobile.jpg"
              alt="SquareOneJobs Mobile listing screen"
            />
          </div>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/review-mobile.jpg"
              alt="SquareOneJobs Mobile job done screen"
            />
          </div>
        </div>
        <div class={style.laptopsContainer}>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/home-web.jpg"
              alt="SquareOneJobs Web home screen"
            />
          </div>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/portfolio-web.jpg"
              alt="SquareOneJobs Web portfolio screen"
            />
          </div>
          <div>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/mockups/employer-home-web.jpg"
              alt="SquareOneJobs Web employer home screen"
            />
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50} ${style.hifiSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PrototypeIcon />
          </div>
          <div>
            <h1>Hi-Fi Prototyping & Final Design</h1>
            <p>
              Here are screenshots of the hi-fi prototypes for the mobile and
              web apps created in Figma.
            </p>
          </div>
        </div>
        <div class={style.imgContainer}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/prototype-mobile.jpg"
            alt="SquareOneJobs mobile app prototype"
          />
          <p class={gs.caption}>Mobile App Prototype</p>
        </div>
        <div class={style.imgContainer}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/squareonejobs/prototype-web.jpg"
            alt="SquareOneJobs web app prototype"
          />
          <p class={gs.caption}>Web App Prototype</p>
        </div>
      </div>
      <div class={style.bgGreen}>
        <div class={`${gs.section} ${gs.section40} ${style.goingForward}`}>
          <h1>Going Forward</h1>
          <h3>IMPACT</h3>
          <p>
            Without any formal marketing campaign, as of March 1, 2022, we have
            over 100 users who have created accounts. After talking with
            nonprofits, job seekers, and businesses, our users are thrilled to
            get started using the app and apply to jobs they love.
          </p>
          <h3>WHAT I LEARNED</h3>
          <p>
            Being my first major design project, SquareOneJobs has been the
            biggest learning experience of my life. Going out into the real
            world and empathizing with users, ideating dozens of ideas, deciding
            the solution we want to tackle, creating mockups and prototypes, and
            testing our product with users are the steps we used to get to where
            we are now.
          </p>
          <p>
            Aside from the design process, I learned the ins-and-outs of Figma
            and Adobe XD: how to create digital wireframes, lo-fi prototypes,
            mockups, and hi-fi prototypes.
          </p>
          <p>
            Finally, going beyond the UX portion of this project, I’ve learned
            how to incorporate and build a company from scratch. I got the
            chance to harness my coding skills and develop both the frontend and
            backends for our service (using HTML, CSS, ReactJS, NodeJS, and
            plenty of other APIs and services).
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.nextStepsSection}`}>
        <h1>Next Steps</h1>
        <div class={style.split}>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>1</div>
            <h2>More Features</h2>
            <p>
              In the ever-expanding job market it’s important to continually be
              innovative by creating more helpful features for both job seekers
              and employers.
            </p>
          </div>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>2</div>
            <h2>Usability Studies</h2>
            <p>
              Conduct more usability studies as we continually add new features,
              making sure that users are happy with the changes
            </p>
          </div>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>3</div>
            <h2>Development</h2>
            <p>
              As we’ve already developed v1.0, we’re constantly implementing the
              new features and ironing out all the bugs.
            </p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section40} ${gs.wrapUpSection}`}>
        <h1>Big thank you for checking out my work on SquareOneJobs!</h1>
        <p>
          As we keep working, we're constantly looking for input and
          connections! I’d love to chat, my door’s always open.
        </p>
      </div>
    </div>
  );
};
export default SquareOneJobs;
