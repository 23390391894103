import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import ArrowIcon from "../icons/ArrowIcon";
import style from "./style.css";

const UpArrow = () => {
  const { height } = useWindowDimensions();
  const [divStyle, setDivStyle] = useState([style.upArrowContainer]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    const classes =
      typeof window !== "undefined" && window.scrollY > height
        ? `${style.upArrowContainer} ${style.visible}`
        : `${style.upArrowContainer}`;
    setDivStyle(classes);
  };

  return (
    <div
      class={divStyle}
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      }
    >
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};
export default UpArrow;
