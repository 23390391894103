import { h } from "preact";
import style from "./style.css";
import useCards from "../../../hooks/useCards";
import Card from "../../../components/card";
import { Link } from "preact-router";

const S1JMobile = () => {
  const cards = useCards();

  return (
    <div class={`${style.project}`}>
      <div class={style.header}>
        <div class={style.left}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
            alt="SquareOneJobs Logo"
          />
          <h1>SquareOneJobs Mobile</h1>
          <p>Designer & Developer | 2021-2022</p>
        </div>
        <img
          class={style.phone}
          src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/S1JMobileListing.png"
          alt="SquareOneJobs mobile app listing screen"
        />
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>COMPANY</p>
              <p class={style.info}>SquareOneJobs</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>POSITION</p>
              <p class={style.info}>Co-founder/CEO</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>2021-2022</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Mobile Designer & Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>Figma</p>
              <p class={style.info}>React Native</p>
              <p class={style.info}>Expo</p>
              <p class={style.info}>GitHub</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>The Problem</h2>
        <p>
          Societal hurdles prevent people with barriers to employment (i.e.,
          criminal record, veteran, disibility, etc.) from finding stable,
          full-time employment they enjoy.
        </p>
        <p>
          <i>
            This is not the only app for this project! Check out the{" "}
            <Link href="/work/squareonejobs-web">web app</Link> and{" "}
            <Link href="/work/squareonejobs-backend">backend</Link> reports.
          </i>
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Solution</h2>
        <p>
          An online job service that builds meaningful connections using the gig
          economy to build user credibility, a smart hiring piepline so
          employers are never understaffed, and A.I. to match employers with the
          perfect candidate.
        </p>
        <p>
          After already completing the web app, we realized gig workers need
          their job info on the go. So we put our heads down to design and
          develop a mobile app that users will love.
        </p>
      </div>
      <div class={style.phonesSection}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/S1JMobileExplore.png"
          alt="SquareOneJobs mobile app explore screen"
        />
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/S1JMobileListing.png"
          alt="SquareOneJobs mobile app listing screen"
        />
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/S1JMobileReview.png"
          alt="SquareOneJobs mobile app review screen"
        />
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Approach</h2>
        <p>
          Working with users that may not be the most tech savvy, we knew we
          needed a simple, intuitive interface that still allowed users to
          complete all of their tasks with ease.
        </p>
        <p>
          Our startup’s brand voice was to be the “experienced mentor” and act
          as a “kind, helping hand.” To resemble this in our design, we added
          rounded corners, a friendly, sans-serif font (Montserrat), and a
          clean, yet diverse color palette.
        </p>
      </div>
      <div class={`${style.bgDark}`}>
        <div
          class={`${style.section} ${style.sectionMd} ${style.S1JMobileGamifyingWork}`}
        >
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/S1JMobileDashboard.png"
            alt="SquareOneJobs mobile app dashboard screen"
          />
          <div>
            <h2>Gamifying Work</h2>
            <p>
              Work can be hard, but what if you could turn it into a game? The
              home screen of the app provides all of the “quick glance”
              information you’ll need, including: an overview of the current
              day’s job, any suggested tasks, as well as any upcoming jobs the
              user might have that week.
            </p>
            <p>
              To make it less boring, we’ve added a point system so users can
              earn points after applying to jobs, completing tasks, and
              completing day jobs. As the user levels-up their account, we know
              they’ve been working hard at what they do, so we’re confident to
              recommend them to employers for future jobs. The more points they
              earn, the more we recommend them.
            </p>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status</h2>
        <p>
          The official SquareOneJobs Mobile app was released in November 2021.
          Minor updates will still be released over the next few months.
        </p>
      </div>
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Web App")
              ]
            }
          />
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Backend")
              ]
            }
          />
        </div>
      </div>
    </div>
  );
};
export default S1JMobile;
