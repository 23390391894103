import { h } from "preact";

const PuzzleIcon = (props) => (
  <svg
    height={512}
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M310.174 73.25c7.018-6.283 16.4-9.979 26.651-9.573 19.511.772 35.393 16.681 36.13 36.194.812 21.479-16.36 39.147-37.658 39.147-9.654 0-18.456-3.634-25.124-9.603v72.412h72.412c-6.283 7.018-9.979 16.4-9.573 26.651.772 19.511 16.681 35.393 36.194 36.13 21.479.812 39.146-16.36 39.146-37.658 0-9.654-3.634-18.456-9.604-25.124h73.25V25.124C512 11.248 500.751 0 486.876 0H310.174zM264.665 336.825c-.405-10.251 3.291-19.633 9.573-26.651h-72.412v72.412c-7.018-6.283-16.4-9.979-26.651-9.573-19.511.772-35.393 16.681-36.13 36.194-.812 21.479 16.36 39.146 37.658 39.146 9.654 0 18.456-3.634 25.124-9.603V512h176.702c13.875 0 25.124-11.248 25.124-25.124V310.174h-73.25c5.969 6.668 9.603 15.47 9.603 25.124 0 21.298-17.668 38.469-39.146 37.658-19.514-.738-35.424-16.619-36.195-36.131zM226.949 171.995c-9.654 0-18.456 3.634-25.124 9.604v-73.25H25.124C11.248 108.349 0 119.597 0 133.472v146.702h33.82c2.448-34.082 30.559-61.461 64.917-62.759a68.297 68.297 0 0 1 2.595-.049c35.682 0 65.003 27.754 67.511 62.808h32.983v-42.412c7.018 6.283 16.4 9.979 26.651 9.573 19.511-.772 35.393-16.681 36.13-36.194.812-21.478-16.36-39.146-37.658-39.146z" />
    <path d="M127.942 457.612c-12.875-13.371-19.579-30.965-18.876-49.539 1.298-34.357 28.678-62.468 62.76-64.916v-32.983h-42.413c5.969-6.668 9.604-15.47 9.604-25.124 0-21.298-17.668-38.469-39.146-37.658-19.513.738-35.422 16.619-36.194 36.13-.405 10.251 3.291 19.633 9.573 26.651H0v176.702C0 500.752 11.248 512 25.124 512h146.703v-33.825c-16.549-1.213-32.324-8.556-43.885-20.563z" />
  </svg>
);

export default PuzzleIcon;
