import { h } from "preact";
import { useState } from "preact/hooks";
import style from "./style.css";
import Me from "../../assets/common/nate-headshot.jpg";
import Button from "../../components/button";

const About = () => {
  const [isButtonActive, setIsButtonActive] = useState(false);

  return (
    <div class={style.about}>
      <div class={style.container}>
        <div class={style.imgContainer}>
          <img src={Me} alt="Picture of Nate with the NYC skyline in the background" />
        </div>
        <div class={style.body}>
          <div class={style.headerContainer}>
            <h1>Nate Renner 👋</h1>
            <Button
              link={
                "https://drive.google.com/file/d/1jGIZujSkfJ4gIAGYl6cVi9NpZ3uLj9XL/view?usp=sharing"
              }
              color="gradient"
              text="Resume"
              active={isButtonActive}
              onMouseOver={() => setIsButtonActive(true)}
              onMouseLeave={() => setIsButtonActive(false)}
              onClick={() => true}
            />
          </div>
          <div class={style.text}>
            <p class={style.bold}>
              I'm a frontend engineer (web & mobile), UX designer, and entrepreneur, looking to
              solve big issues and create meaningful change.
            </p>
            <p>
              Over the past 3 years, I’ve been working on a startup I’ve co-founded called
              SquareOneJobs. Through this, I’ve researched and learned UX design, how to build web &
              mobile applications to production, as well as how to create secure and optimized
              backend systems.
            </p>
            <p>
              At my core, I’m passionate about UI/UX design. I’ve learned that creating beautiful
              and usable designs not only keep users engaged, but help them get the most out of the
              products & services they love.
            </p>
            {/* <p>
              Currently, I'm in my 4th year at Stevens Institute of Technology finishing my
              undergrad in Computer Engineering (B.E.).
            </p> */}
            <p class={style.bold}>Reach out to me, I’d love to chat!</p>
            <p>nathanrenner00@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
