import { h } from "preact";
import useMousePosition from "../../../hooks/useMousePosition";
import style from "../style.css";
import ArrowIcon from "../../../components/icons/ArrowIcon";

const Header = ({ workRef }) => {
  const { clientX, clientY } = useMousePosition();

  return (
    <div class={style.header} id="header">
      <div
        class={style.circle}
        style={{
          top: `${clientY / 10}px`,
          right: `-${clientX / 10}px`,
        }}
      />
      <div class={style.container}>
        <div>
          <h1>
            Hi, I'm <span class={style.nate}>Nate</span>!
          </h1>
          <p>
            I'm a frontend engineer (web & mobile), UX designer, and entrepreneur. I love to come up
            with creative digital solutions for societal problems.
          </p>
        </div>
      </div>
      <div class={style.arrowContainer}>
        <div
          class={style.arrow}
          onClick={() =>
            workRef?.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            })
          }
        >
          <ArrowIcon class={style.icon} />
        </div>
      </div>
    </div>
  );
};

export default Header;
