import { h } from "preact";
import style from "./style.css";

const Loader = ({ isActive }) => {
  if (!isActive) return;
  return (
    <div class={style.loaderContainer}>
      <div class={style.loader} />
      <div class={style.loader} />
      <div class={style.loader} />
    </div>
  );
};
export default Loader;
