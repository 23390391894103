import { h } from "preact";
import style from "./style.css";
import { Link } from "preact-router";
import Card from "../../../components/card";
import useCards from "../../../hooks/useCards";

const S1JBackend = () => {
  const cards = useCards();

  return (
    <div class={style.project}>
      <div class={`${style.headerCenter}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
          alt="SquareOneJobs Logo"
        />
        <h1>SquareOneJobs Backend</h1>
        <p>Lead Backend Developer | 2019-2022</p>
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>COMPANY</p>
              <p class={style.info}>SquareOneJobs</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>POSITION</p>
              <p class={style.info}>Co-founder/CEO</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>2019-2022</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Lead Backend Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>NodeJS</p>
              <p class={style.info}>ExpressJS</p>
              <p class={style.info}>AWS S3</p>
              <p class={style.info}>StripeJS</p>
              <p class={style.info}>Mailgun</p>
              <p class={style.info}>MongoDB</p>
              <p class={style.info}>Mongoose</p>
              <p class={style.info}>Google 0Auth</p>
              <p class={style.info}>Postman</p>
              <p class={style.info}>Heroku</p>
              <p class={style.info}>GitHub</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>The Problem</h2>
        <p>
          Societal hurdles prevent people with barriers to employment (i.e.,
          criminal record, veteran, disibility, etc.) from finding stable,
          full-time employment they enjoy.
        </p>
        <p>
          <i>
            This is not the only app for this project! Check out the{" "}
            <Link href="/work/squareonejobs-mobile">mobile</Link> and{" "}
            <Link href="/work/squareonejobs-web">web app</Link> reports.
          </i>
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Solution</h2>
        <p>
          An online job service that builds meaningful connections using the gig
          economy to build user credibility, a smart hiring piepline so
          employers are never understaffed, and a.i. to match employers with the
          perfect candidate.
        </p>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>The Approach</h2>
        <p>
          For most modern web and mobile apps, REST backends are the way to go.
          We ended up using the MERN stack, with NodeJS running the backend. I
          was fairly new to using Node, but was extremely familiar with
          Javascript, so it didn’t take a lot of time to learn (plus, since then
          I’ve had hundreds of hours with these technologies).
        </p>
      </div>
      <div class={style.bgDark}>
        <div
          class={`${style.section} ${style.sectionLg} ${style.S1JBackendToolsUsed}`}
        >
          <div class={style.toolsContainer}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/nodejslogo.png"
              alt="NodeJS logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/expresslogo.png"
              alt="ExpressJS logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/mongodblogo.png"
              alt="MongoDB logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/awslogo.png"
              alt="AWS logo"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/stripelogo.png"
              alt="Stripe logo"
            />
          </div>
          <div>
            <h2>Tools Used</h2>
            <p>
              Our goal with the backend was to get a functional backend up and
              running as soon as possible. However, that didn’t mean we lacked
              structure. To make things easier to read, code, and to help with
              consistency, ExpressJS was the go-to for the framework. Our API’s
              used Mongoose to talk to a MongoDB server containing all of the
              stored data.{" "}
            </p>
            <p>
              For storing images and documents, an AWS S3 bucket was set up, and
              AWS’s Node module helped to speed things up.{" "}
            </p>
            <p>
              Payment processing was done using StripeJS. Their beautiful
              documentation was extremely helpful in learning how to use their
              API’s for the first time.
            </p>
            <p>
              Mailgun was used to send account verification and confirmation
              emails to users.
            </p>
            <p>Lastly, the application was hosted on a Heroku server.</p>
          </div>
        </div>
      </div>
      <div
        class={`${style.section} ${style.sectionLg} ${style.S1JBackendAPIsGalore}`}
      >
        <div>
          <h2>APIs Galore</h2>
          <p>
            As I was completing the backend and most of the frontend work, I
            knew both the Node APIs and React components extremely well.
            However, my business partner was only working on the front end,
            causing some confusion with the vast amount of possible endpoints.
          </p>
          <p>
            To simplify this, I created a chart (to the right) explaining which
            APIs are currently in use with which components. This helped clear
            up any miscommunication, and I learned that creating simple charts
            like these can create more efficient workflows in a team setting.
          </p>
          <p>
            And yes, this chart was only for the listing actions - there were at
            least 10 more of these charts!
          </p>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/apichart.png"
          alt="API Chart for backend routes"
        />
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status</h2>
        <p>
          The SquareOneJobs web app and mobile apps were released in July 2021
          and November 2021, respectively. Minor updates will still be released
          over the next few months.
        </p>
      </div>
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards
                  .map((c) => c.projTitle)
                  .indexOf("SquareOneJobs Mobile App")
              ]
            }
          />
          <Card card={cards[cards.map((c) => c.projTitle).indexOf("TraQ")]} />
        </div>
      </div>
    </div>
  );
};
export default S1JBackend;
