import { h } from "preact";

const PrototypeIcon = (props) => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M44.341 2.295H33.002c-1.72 0-3.12 1.4-3.12 3.12v1.763h17.579V5.414c0-1.72-1.4-3.12-3.12-3.12ZM29.883 29.449c0 1.72 1.4 3.12 3.12 3.12H44.34c1.72 0 3.12-1.4 3.12-3.12v-1.764H29.883v1.764ZM29.883 17.92h9.075l-1.12-1.12a.488.488 0 1 1 .69-.69l1.954 1.953a.489.489 0 0 1 0 .69l-1.953 1.953a.488.488 0 1 1-.69-.69l1.119-1.12h-9.075v7.813H47.46V8.154H29.883v9.766ZM10.84 36.475a3.422 3.422 0 0 1-3.418-3.418 3.422 3.422 0 0 1 3.418-3.418 3.423 3.423 0 0 1 3.382 2.93h5.895v-9.766H2.54v18.554h17.578v-7.812h-5.895a3.423 3.423 0 0 1-3.382 2.93ZM20.117 20.063c0-1.72-1.4-3.12-3.12-3.12H5.66c-1.72 0-3.12 1.4-3.12 3.12v1.763h17.578v-1.763Z"
      fill="#51CC8E"
    />
    <path
      d="M10.84 30.615a2.444 2.444 0 0 0-2.442 2.442 2.444 2.444 0 0 0 2.442 2.441 2.444 2.444 0 0 0 2.441-2.441 2.444 2.444 0 0 0-2.441-2.442ZM2.54 44.097c0 1.72 1.398 3.12 3.119 3.12h11.339c1.72 0 3.12-1.4 3.12-3.12v-1.763H2.538v1.763ZM27.756 17.92a3.737 3.737 0 0 0-3.733 3.733v8.159a2.76 2.76 0 0 1-2.756 2.756h-1.15v.977h1.15A3.737 3.737 0 0 0 25 29.812v-8.16a2.76 2.76 0 0 1 2.756-2.756h2.127v-.976h-2.127Z"
      fill="#51CC8E"
    />
  </svg>
);

export default PrototypeIcon;
