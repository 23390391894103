import { h } from "preact";
const LinkedInIcon = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 22.5v-7.325c0-3.6-.775-6.35-4.975-6.35-2.025 0-3.375 1.1-3.925 2.15h-.05V9.15H9.575V22.5h4.15v-6.625c0-1.75.325-3.425 2.475-3.425 2.125 0 2.15 1.975 2.15 3.525v6.5h4.15v.025ZM2.825 9.15h4.15V22.5h-4.15V9.15ZM4.9 2.5a2.4 2.4 0 0 0-2.4 2.4c0 1.325 1.075 2.425 2.4 2.425s2.4-1.1 2.4-2.425a2.4 2.4 0 0 0-2.4-2.4Z"
      fill="#fff"
    />
  </svg>
);

export default LinkedInIcon;
