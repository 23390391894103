import { h } from "preact";

const ClipboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 409.6 409.6"
    style={{
      enableBackground: "new 0 0 409.6 409.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M247.465 0h-85.33c-9.385 0-17.07 7.685-17.07 17.065v42.67c0 9.38 7.685 17.065 17.07 17.065h85.33c9.385 0 17.07-7.685 17.07-17.065v-42.67c0-9.38-7.685-17.065-17.07-17.065z" />
    <path d="M341.335 34.135h-51.2v34.13c0 18.77-15.37 34.135-34.135 34.135H153.6c-18.765 0-34.135-15.365-34.135-34.135v-34.13h-51.2c-18.765 0-34.13 15.365-34.13 34.13v307.2c0 18.77 15.365 34.135 34.13 34.135h273.07c18.765 0 34.13-15.365 34.13-34.135v-307.2c0-18.765-15.365-34.13-34.13-34.13zM102.4 170.665h102.4V204.8H102.4v-34.135zm170.665 170.67H102.4V307.2h170.665v34.135zm34.135-68.27H102.4v-34.13h204.8v34.13z" />
  </svg>
);

export default ClipboardIcon;
