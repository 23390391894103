import { h } from "preact";
import Button from "../../../components/button";
import style from "./style.css";
import gs from "../general.css";

import DevicesIcon from "../../../components/icons/DevicesIcon";
import QuestionIcon from "../../../components/icons/QuestionIcon";
import StarIcon from "../../../components/icons/StarIcon";
import WiresIcon from "../../../components/icons/WiresIcon";
import PuzzleIcon from "../../../components/icons/PuzzleIcon";
import PaperIcon from "../../../components/icons/PaperIcon";

const ThriveFitness = () => {
  return (
    <div class={`${gs.project} ${style.thriveFitness}`}>
      <img
        class={gs.hero}
        src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/hero.png"
        alt="Banner of Thrive Logo & Mobile Phones"
      />
      <div
        class={`${gs.projectDetailsSection}  ${style.projectDetailsSection}`}
      >
        <div class={`${gs.projectDetailsInner} ${style.projectDetailsInner}`}>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>ROLE</h3>
            <h2>UX Designer</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>COMPANY</h3>
            <h2>Google UX Certificate</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>TIMEFRAME</h3>
            <h2>March 2022</h2>
          </div>
          <Button
            color="thrivePink"
            link="https://drive.google.com/file/d/1I30lZ4xGm2J86gjJZOfjGH9StsOPBlRu/view?usp=sharing"
            text="CASE STUDY"
            active={false}
          />
        </div>
      </div>
      <div class={gs.toolsUsedSection}>
        <h3>TOOLS & CONCEPTS</h3>
        <h2>Figma · Mobile Design · Responsive Web Design</h2>
      </div>
      <div
        class={`${gs.section} ${gs.projectOverviewSection} ${style.projectOverviewSection}`}
      >
        <div class={gs.imgContainer}>
          <img
            class={gs.phone}
            src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockup-mobile-home.png"
            alt=""
          />
        </div>
        <div class={gs.right}>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <DevicesIcon />
            </div>
            <div>
              <h3>PRODUCT DESCRIPTION</h3>
              <h2>
                Thrive Fitness is a simple fitness service used to aid those who
                are new to technology throughout their fitness journey. Designed
                for both mobile & responsive web.
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <QuestionIcon />
            </div>
            <div>
              <h3>PROBLEM</h3>
              <h2>
                It’s often difficult for those who are new to technology to use
                it to improve their fitness habits{" "}
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <StarIcon />
            </div>
            <div>
              <h3>PROJECT GOAL</h3>
              <h2>
                Design a simple fitness service for individuals who are new to
                technology.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class={style.bgGreen}>
        <div
          class={`${gs.section} ${gs.section60} ${style.understandingSection}`}
        >
          <h1>Understanding the User</h1>
          <div class={style.split}>
            <div>
              <div class={style.personaHeader}>
                <img
                  src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/avatar.png"
                  alt="image of a user avatar"
                />
                <div>
                  <h3>PERSONA 1: ELEANOR JENKINS</h3>
                  <p>
                    <b>Age:</b> 64
                  </p>
                  <p>
                    <b>Education:</b> Associates
                  </p>
                  <p>
                    <b>Occupation:</b> Retired
                  </p>
                </div>
              </div>
              <h3>ELEANOR'S STORY</h3>
              <o>
                Ellie has lived a long, busy life and now that she’s retired,
                she realizes that she’s slowing down. She wants a way to stay
                active, but technology can be confusing to her at times. Being
                able to play with her grandkids is her motive.
              </o>
            </div>
            <div class={style.summary}>
              <h3>SUMMARY OF USER RESEARCH</h3>
              <p>
                In the beginning, I conducted user interviews and created
                empathy maps to try and understand the needs of the target
                users. I learned that our target users tend to give up with a
                product that has too many interactions. They would much prefer a
                simple, clean look where they can easily infer where to press
                next.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Pain Points</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Navigation</h2>
            <p>
              When navigating through the application, users were having a
              difficult time knowing where to navigate to
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Buttons</h2>
            <p>
              When buttons aren’t labeled correctly or have a clear presence,
              users often times overlook them
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Accessibility</h2>
            <p>
              As our target users are much later in their lives, there are more
              accessibility concerns to address
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgBlue}>
        <div class={`${gs.section} ${style.startingCreationSection}`}>
          <h1>Starting the Creation</h1>
          <p>
            After the first round of talking to our target users, it was time to
            start designing. Even though I was designing a mobile-first app and
            later transitioning to a responsive web design, I decided to lay out
            a sitemap to get things started. I also created a few paper
            wireframes.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/sitemap.png"
          alt="sitemap for thrive fitness site."
        />
        <p class={gs.caption}>Sitemap</p>
      </div>
      <div
        class={`${gs.section} ${gs.section60} ${style.paperWireframesSection}`}
      >
        <img
          src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/paper-wireframes.png"
          alt="paper wireframes for thrive fitness mobile app."
        />
        <p class={gs.caption}>Paper Wireframes</p>
      </div>
      <div
        class={`${gs.section} ${gs.section70} ${style.digitalWireframesSection}`}
      >
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <WiresIcon />
          </div>
          <div>
            <h1>Digital Wireframes</h1>
            <p>
              Eventually I moved on to creating digital wireframes of each
              screen, including multiple screen sizes.
            </p>
          </div>
        </div>
        <div class={style.imgContainer}>
          <img
            class={style.laptop}
            src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/wireframe-home-web.png"
            alt="digital wireframe of thrive fitness website"
          />
          <img
            class={style.phone}
            src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/wireframe-home-mobile.png"
            alt="digital wireframe of thrive fitness mobile app"
          />
        </div>
      </div>
      <div class={`${gs.section} ${gs.section70} ${style.loFiSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PuzzleIcon />
          </div>
          <div>
            <h1>Lo-Fi Prototype</h1>
            <p>
              Here’s a screenshot of the lo-fi prototype created in Figma. You
              can see the interactive model{" "}
              <a
                href="https://www.figma.com/proto/G0mhD5atOtHp3KXY4CD7Pf/Thrive-Fitness-App?page-id=1%3A379&node-id=1%3A654&viewport=241%2C48%2C0.12&scaling=min-zoom&starting-point-node-id=1%3A654"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/lo-fi-prototype.png"
          alt="lo-fi wireframe for mobile app"
        />
      </div>
      <div class={`${gs.section} ${style.mockupsSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <DevicesIcon />
          </div>
          <div>
            <h1>Mobile & Web Mockups</h1>
          </div>
        </div>
        <div class={style.imgsContainer}>
          <div class={style.row}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-quiz.png"
              alt="Mobile Quiz Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-quiz.png"
              alt="Web Quiz Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-home.png"
              alt="Mobile Home Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-home.png"
              alt="Web Home Screen"
            />
          </div>
          <div class={style.row}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-account.png"
              alt="Web Account Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-account.png"
              alt="Mobile Account Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-activity.png"
              alt="Web Activity Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-activity.png"
              alt="Mobile Activity Screen"
            />
          </div>
          <div class={style.row}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-workout.png"
              alt="Mobile Workout Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-workout.png"
              alt="Webb Workout Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/mobile-start.png"
              alt="Mobile Choose Workout Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/mockups/web-start.png"
              alt="Web Choose Workout Screen"
            />
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${style.hiFiSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PaperIcon />
          </div>
          <div>
            <h1>Hi-Fi Prototyping & Final Design</h1>
            <p>
              Here’s a screenshot of the hi-fi prototype created in Figma. You
              can see the interactive model for web{" "}
              <a
                href="https://www.figma.com/proto/G0mhD5atOtHp3KXY4CD7Pf/Thrive-Fitness-App?page-id=5%3A739&node-id=15%3A1392&viewport=241%2C48%2C0.05&scaling=scale-down&starting-point-node-id=15%3A1392&show-proto-sidebar=1"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              and mobile{" "}
              <a
                href="https://www.figma.com/proto/G0mhD5atOtHp3KXY4CD7Pf/Thrive-Fitness-App?page-id=5%3A739&node-id=5%3A1112&viewport=241%2C48%2C0.05&scaling=min-zoom&starting-point-node-id=5%3A1112&show-proto-sidebar=1"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/hi-fi-prototype.png"
          alt="Prototyping image showing final design for web and mobile apps"
        />
      </div>
      <div class={`${gs.section} ${gs.section40} ${style.stylesheetSection}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/stylesheet.png"
          alt="Styles (colors, fonts, icons) used in desing"
        />
        <p class={gs.caption}>Design Stylesheet</p>
      </div>
      <div class={style.bgPurple}>
        <div
          class={`${gs.section} ${gs.section50} ${style.goingForwardSection}`}
        >
          <h1>Going Forward</h1>
          <div class={style.split}>
            <div>
              <h3>IMPACT</h3>
              <p>
                Our testers loved the final product and genuinely wanted to see
                it be created. A simple, clean app is sometimes all someone’s
                looking for.
              </p>
            </div>
            <div>
              <h3>WHAT I LEARNED</h3>
              <p>
                This project taught me that sometimes less is way more. A lot of
                times in design you don’t need all the bells and whistles, you
                just need a clean and simple user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section40} ${style.nextStepsSection}`}>
        <h1>Next Steps</h1>
        <div class={style.split}>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>1</div>
            <h2>Usability Studies</h2>
            <p>
              Conduct another round of usability studies to be sure our users
              have no more major issues
            </p>
          </div>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>2</div>
            <h2>More Features</h2>
            <p>
              Less is more in this case, but that doesn’t mean you can’t add new
              things!
            </p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50} ${gs.wrapUpSection}`}>
        <h1>Thanks for reviewing my work on Thrive Fitness!</h1>
        <p>I’d love to chat, my door’s always open.</p>
      </div>
    </div>
  );
};
export default ThriveFitness;
