import { h } from "preact";
import style from "./style.css";

const Footer = () => {
  return (
    <div class={style.footer}>
      <div class={style.container}>
        Designed & Created by Nate Renner © {new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Footer;
