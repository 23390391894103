import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import Button from "../../components/button";
import Card from "../../components/card";
import useCards from "../../hooks/useCards";

import style from "./style.css";

const buttons = [
  {
    text: "All",
    projType: "all",
    color: "gradient",
  },
  {
    text: "UX Design",
    projType: "ux",
    color: "purple",
  },
  {
    text: "Frontend Dev",
    projType: "fe",
    color: "blue",
  },
  {
    text: "Backend Dev",
    projType: "be",
    color: "green",
  },
];

const Work = () => {
  const cards = useCards();
  const [active, setActive] = useState("all");
  const [currentCards, setCurrentCards] = useState(cards);

  useEffect(() => {
    if (active === "all") setCurrentCards(cards);
    else setCurrentCards(cards.filter((c) => c.projType === active));
  }, [active]);

  const renderButtons = buttons.map((btn) => (
    <Button
      key={btn.id}
      active={active === btn.projType}
      text={btn.text}
      onClick={() => setActive(btn.projType)}
      color={btn.color}
    />
  ));

  const renderCards = currentCards.map((card, i) => (
    <Card card={card} key={`${card.projType}${i}`} />
  ));

  return (
    <div class={style.work}>
      <div class={style.container}>
        <div class={style.header}>
          <h1>Selected Projects</h1>
          <p class={style.subtitle}>Life looks more beautiful when things are designed well.</p>
          <p>
            I’ve spent hundreds of hours with web development, Javascript frameworks (like React),
            REST API’s, and backend services. Recently, I’ve been fascinated with and accelerating
            my UI/UX design skills.
          </p>
        </div>
        <div class={style.buttonContainer}>{renderButtons}</div>
        <div class={style.cardsContainer}>{renderCards}</div>
      </div>
    </div>
  );
};

export default Work;
