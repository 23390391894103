import { h } from "preact";
import Button from "../../../components/button";
import DevicesIcon from "../../../components/icons/DevicesIcon";
import PuzzleIcon from "../../../components/icons/PuzzleIcon";
import QuestionIcon from "../../../components/icons/QuestionIcon";
import StarIcon from "../../../components/icons/StarIcon";
import WiresIcon from "../../../components/icons/WiresIcon";
import ClipboardIcon from "../../../components/icons/ClipboardIcon";
import LocationIcon from "../../../components/icons/LocationIcon";
import GroupIcon from "../../../components/icons/GroupIcon";
import HourglassIcon from "../../../components/icons/HourglassIcon";
import ResearchIcon from "../../../components/icons/ResearchIcon";
import MobileIcon from "../../../components/icons/MobileIcon";

import gs from "../general.css";
import style from "./style.css";

const Moviegoers = () => {
  return (
    <div class={`${style.moviegoers} ${gs.project}`}>
      <img
        class={gs.hero}
        src="https://nr-portfolio.s3.amazonaws.com/moviegoers/hero.png"
        alt="Banner of Moviegoers Logo & Mobile Phones"
      />
      <div
        class={`${gs.projectDetailsSection}  ${style.projectDetailsSection}`}
      >
        <div class={`${gs.projectDetailsInner} ${style.projectDetailsInner}`}>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>ROLE</h3>
            <h2>UX Designer</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>COMPANY</h3>
            <h2>Google UX Certificate</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>TIMEFRAME</h3>
            <h2>February 2022</h2>
          </div>
          <Button
            color="moviegoersPurple"
            link="https://drive.google.com/file/d/1asPI0v3dkfCmobkWJxnhBGc2fDNgVINk/view?usp=sharing"
            text="CASE STUDY"
            active={false}
          />
        </div>
      </div>
      <div class={gs.toolsUsedSection}>
        <h3>TOOLS & CONCEPTS</h3>
        <h2>Figma · Mobile Design</h2>
      </div>
      <div
        class={`${gs.section} ${gs.projectOverviewSection} ${style.projectOverviewSection}`}
      >
        <div class={gs.imgContainer}>
          <img
            class={gs.phone}
            src="https://nr-portfolio.s3.amazonaws.com/moviegoers/home-mobile.png"
            alt="Moviegoers App Home Screen"
          />
        </div>
        <div class={gs.right}>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <DevicesIcon />
            </div>
            <div>
              <h3>PRODUCT DESCRIPTION</h3>
              <h2>
                The Moviegoers app is a one-stop shop for everything about going
                to the movies. We strive to show users every trailer they’re
                hoping to see & provide them with the ability to chat with
                friends, and book tickets together.
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <QuestionIcon />
            </div>
            <div>
              <h3>PROBLEM</h3>
              <h2>
                It’s difficult to buy movie tickets online as a group of people
                who want to each pay separately.
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <StarIcon />
            </div>
            <div>
              <h3>PROJECT GOAL</h3>
              <h2>
                Design an app where users can watch trailers, chat with their
                friends about upcoming movies, and book tickets together.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class={style.bgPurple}>
        <div class={`${gs.section} ${gs.section30} ${style.understandingUser}`}>
          <h1>Understanding the User</h1>
          <p>
            I conducted interviews with users and created empathy maps to lay
            out their needs. The primary user group were individuals who go to
            the movies every 1 to 2 months. The research concluded initial
            assumptions about the desire for a movie trailer app where
            individuals can buy tickets as a group, but have the ability to pay
            separately.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.meetTheUsers}`}>
        <h1>Meet the Users</h1>
        <div class={style.splitSection}>
          <div class={style.persona}>
            <div class={style.personaHeader}>
              <img
                src="https://nr-portfolio.s3.amazonaws.com/moviegoers/vanessa.png"
                alt="Avatar image of female with long dark hair"
              />
              <div>
                <h2>Persona 1: Vanessa</h2>
                <p>
                  Vanessa has been a movie lover all 34 years of her life, and
                  wants instant access to trailers of her new movies. She’s been
                  frustrated with having to download multiple apps to see them
                  while sharing with friends.
                </p>
              </div>
            </div>
            <h3>Goals</h3>
            <p>
              Has experience in the field of computer science and is looking for
              fulfilling employment.
            </p>
            <h3>Frustrations</h3>
            <p>
              Sends movie trailers to friends, but has to download multiple
              different apps to see different trailers
            </p>
          </div>
          <div class={style.persona}>
            <div class={style.personaHeader}>
              <img
                src="https://nr-portfolio.s3.amazonaws.com/moviegoers/jeff.png"
                alt="Avatar image of male with brown beanie"
              />
              <div>
                <h2>Persona 2: Jeff</h2>
                <p>
                  Jeff’s a high school senior who loves anime, cop shows, and
                  most of all, Marvel movies. While going back through the MCU,
                  he wished that all of the official trailers were in one place.
                  Also, because of his color-blindness, he wants to see trailers
                  through the colors he’s able to see.
                </p>
              </div>
            </div>
            <h3>Goals</h3>
            <p>
              Have a high-contract movie trailer that he can experience to fully
              grasp what the movie is like
            </p>
            <h3>Frustrations</h3>
            <p>
              Jeff is color blind, and movie trailers sometimes make it
              difficult to understand what’s happening
            </p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50} ${style.sitemap}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/moviegoers/journey-map.png"
          alt="Moviegoers Journey Map"
        />
        <p class={gs.caption}>Journey Map</p>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Pain Points</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Group Payment</h2>
            <p>
              Movie-goers are tired of paying for a group of tickets, and
              needing to split the cost after the tickets were purchased
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Accessibility</h2>
            <p>
              Often times platforms for movie ticket purchasing are not equipped
              with assistive technologies
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Chat</h2>
            <p>
              People like to keep their chats separate from each other, all in
              one place
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgPurple}>
        <div class={`${gs.section} ${gs.section30} ${style.startingTheDesign}`}>
          <h1>Starting the Design</h1>
          <p>
            After the first round of talking to our target users, it was time to
            start designing. Being a movie trailer app, I wanted to simulate the
            low-light feel of a movie theater. I choose a purple color as an
            accent to balance the dark look of the app.
          </p>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.section60} ${style.digitalWireframesSection}`}
      >
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <WiresIcon />
          </div>
          <div>
            <h1>Digital Wireframes</h1>
            <p>
              As this project was solely a mobile app, I started by creating
              digital wireframes (after jotting a few paper wireframes down).
            </p>
          </div>
        </div>
        <div class={style.imgContainer}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/moviegoers/wireframes.png"
            alt="digital wireframe of moviegoers app"
          />
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.prototypeSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PuzzleIcon />
          </div>
          <div>
            <h1>Lo-Fi Prototype</h1>
            <p>
              Here’s a screenshot of the lo-fi prototype created in Figma. You
              can see the interactive model{" "}
              <a
                href="https://www.figma.com/proto/dlr1eqj6mo2L3aIu1qeqSb/Google-UX-Course-Movie-Trailer-App?page-id=0%3A1&node-id=2%3A2&viewport=241%2C48%2C0.22&scaling=scale-down&starting-point-node-id=2%3A34"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/moviegoers/lofi-prototype.png"
          alt="lo-fi wireframe for mobile app"
        />
      </div>
      <div class={`${gs.section} ${gs.section40} ${gs.usabilityParameters}`}>
        <h1>Usability Study Parameters</h1>
        <div class={gs.parametersContainer}>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <ClipboardIcon />
            </div>
            <h2>Study Type</h2>
            <p>Unmoderated, usability study</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <LocationIcon />
            </div>
            <h2>Location</h2>
            <p>United States, Remote</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <GroupIcon />
            </div>
            <h2>Participants</h2>
            <p>5 participants</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <HourglassIcon />
            </div>
            <h2>Length</h2>
            <p>5-10 minutes</p>
          </div>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.section60} ${style.usabilityFindingsSection}`}
      >
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <ResearchIcon />
          </div>
          <div>
            <h1>Usability Study Findings</h1>
            <p>
              I conducted two rounds of studies. The first round helped iron out
              how the users feel about the functionality of the wireframe. The
              second dealt with the hi-fi prototype and especially making sure
              it’s accessible.
            </p>
          </div>
        </div>
        <div class={style.split}>
          <div>
            <h2>Round 1 Findings</h2>
            <ul>
              <li>Users want to watch trailers quickly</li>
              <li>Users want personalization with trailer selection</li>
              <li>Users want to share trailers with ease</li>
            </ul>
          </div>
          <div>
            <h2>Round 2 Findings</h2>
            <ul>
              <li>The hamburger menu needs to be done away with</li>
              <li>
                The ticket selection needs more inputs such as time & date
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class={style.bgPurple}>
        <div class={`${gs.section} ${gs.section30} ${style.refiningTheDesign}`}>
          <h1>Refining the Design</h1>
          <p>
            Now that I have solid data to help pave the way for a great user
            experience, let’s dive in a create the mockups & prototype.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section70} ${style.mockups}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <MobileIcon />
          </div>
          <div>
            <h1>Mockups</h1>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/moviegoers/mockups.png"
          alt="image of mobile mockups: payment summary, order, home, chat, and account screens"
        />
      </div>
      <div
        class={`${gs.section} ${gs.section60} ${style.prototypeSection} ${style.hifi}`}
      >
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <DevicesIcon />
          </div>
          <div>
            <h1>Hi-Fi Prototyping & Final Design</h1>
            <p>
              Here’s a screenshot of the hi-fi prototype created in Figma. You
              can see the interactive model{" "}
              <a
                href="https://www.figma.com/proto/dlr1eqj6mo2L3aIu1qeqSb/Google-UX-Course-Movie-Trailer-App?page-id=34%3A4&node-id=34%3A363&viewport=241%2C48%2C0.25&scaling=scale-down&starting-point-node-id=34%3A363"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/moviegoers/hifi-prototype.png"
          alt="Prototyping image showing final design for mobile apps"
        />
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Accessibility Considerations</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Closed Captions</h2>
            <p>
              Added closed captions to all trailers for those who have hearing
              loss.
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Icons</h2>
            <p>Use self-explanatory icons to make navigation easier.</p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Actions</h2>
            <p>
              Limit use of gestures, or provided other means to navigate through
              application.
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgPurple}>
        <div
          class={`${gs.section} ${gs.section30} ${style.goingForwardSection}`}
        >
          <h1>Going Forward</h1>
          <h3>IMPACT</h3>
          <p>
            The app makes users feel like they can take their movie-going
            experience to the next level.
          </p>
          <h3>WHAT I LEARNED</h3>
          <p>
            Before the Moviegoers project, I never went through extensive
            usability tests for a design. This was an eye-opening experience,
            and stressed the importance of testing with users, not just talking
            with them. I’m excited to dig deeper and tackle new problems.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section40} ${style.nextStepsSection}`}>
        <h1>Next Steps</h1>
        <div class={style.split}>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>1</div>
            <p>
              Conduct another round of usability studies to test whether the
              pain points were addressed or not.
            </p>
          </div>
          <div class={style.step}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>2</div>
            <p>
              Keep iterating and implementing new features to make the app more
              usable and accessible.
            </p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50} ${gs.wrapUpSection}`}>
        <h1>Thanks for reviewing my work on Moviegoers!</h1>
        <p>I’d love to chat, my door’s always open.</p>
      </div>
    </div>
  );
};
export default Moviegoers;
