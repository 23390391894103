import { h } from "preact";
import style from "./style.css";
import ArrowIcon from "../../../components/icons/ArrowIcon";
import useCards from "../../../hooks/useCards";
import Card from "../../../components/card";

const CCFW = () => {
  const cards = useCards();

  return (
    <div class={`${style.project} ${style.CCFW}`}>
      <div class={style.header}>
        <div class={style.left}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-logo.png"
            alt="CCFW Logo"
          />
          <h1>Common Cents Financial Wellness</h1>
          <p>Designer & Developer | October 2021</p>
          <a
            href="https://www.commoncentsfw.com"
            target="_blank"
            rel="noreferrer"
          >
            www.commoncentsfw.com
          </a>
        </div>
        <img
          class={style.laptop}
          src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-laptop-main.png"
          alt="CCFW Laptop Main Page"
        />
      </div>
      <div class={style.keypoints}>
        <div class={style.inner}>
          <div>
            <div class={style.point}>
              <p class={style.label}>PROJECT ROLE</p>
              <p class={style.info}>Designer & Developer</p>
            </div>
            <div class={style.point}>
              <p class={style.label}>YEAR</p>
              <p class={style.info}>October 2021</p>
            </div>
          </div>
          <div>
            <div class={style.point}>
              <p class={style.label}>TOOLS</p>
              <p class={style.info}>Figma</p>
              <p class={style.info}>ReactJS</p>
              <p class={style.info}>HTML</p>
              <p class={style.info}>CSS</p>
              <p class={style.info}>Javascript</p>
              <p class={style.info}>Firebase Hosting</p>
              <p class={style.info}>Firebase Cloud Functions</p>
              <p class={style.info}>Responsive Web Design</p>
              <p class={style.info}>Github</p>
            </div>
          </div>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm} ${style.problem}`}>
        <h2>Introduction</h2>
        <p>
          My brother-in-law is a financial planner and asked if I could design
          and develop a portfolio website for him. Wanting to increase brand
          awareness & marketing, he asked for links to his socials, a YouTube
          channel embed, an about section, a budget template signup form, and a
          contact form. After hearing his thoughts on how he wants the website
          to feel, I began to come up with a few design iterations.
        </p>
      </div>
      <div
        class={`${style.section} ${style.sectionLg} ${style.CCFWDesignIterations}`}
      >
        <img
          src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-design-iterations.png"
          alt="CCFW Home page design iterations"
        />
        <ArrowIcon />
        <img
          src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-screen-main.png"
          alt="CCFW home page screen"
        />
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Design Iterations</h2>
        <p>
          With any good project, it’s important to fall in love with the
          problem, not the solution — as there might be a better solution than
          the one you originally came up with. This is especially the case with
          design, as you’re creating something for your client and their user’s
          needs, not necessarily yourself. Here, we went through multiple
          designs before we picked the desired one.
        </p>
      </div>
      <div class={style.bgDark}>
        <div
          class={`${style.section} ${style.sectionLg} ${style.CCFWLogoBranding}`}
        >
          <div class={style.logosContainer}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-logo.png"
              alt="CCFW logo iterations"
            />
            <div class={style.divider} />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-logos.png"
              alt="CCFW Logos, light and dark"
            />
          </div>
          <div>
            <h2>Logo & Branding</h2>
            <p>
              Recently, I’ve been interested in designing simple logos for
              personal and freelancing uses. My brother-in-law not only wanted a
              website designed and developed, but also a new logo to keep his
              brand consistent across all platforms. There were multiple
              iterations of logos that we went through before we tweaked it to
              his liking.
            </p>
          </div>
        </div>
      </div>
      <div
        class={`${style.section} ${style.sectionMd} ${style.CCFWFirebaseFun}`}
      >
        <img
          src="https://nr-portfolio.s3.amazonaws.com/ccfw/firebase-logo.png"
          alt="Firebase Cloud Functions Logo"
        />
        <div>
          <h2>Firebase Fun</h2>
          <p>
            The more I use Firebase and all it has to offer, the more I realize
            that there’s so much to it. Originally, I was going to set up and
            host a small backend for an emailer to notify my brother-in-law when
            he gets a new message. Then I got thinking - If I’m already using
            Firebase for hosting, let’s try Firebase Functions. And within a
            half hour, the emailer works!
          </p>
        </div>
      </div>
      <div
        class={`${style.section} ${style.sectionSm} ${style.CCFWResponsiveWeb}`}
      >
        <div class={style.imgsContainer}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-phone-main.png"
            alt="CCFW Mobile Header Screen"
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-laptop-main.png"
            alt="CCFW Laptop Header Screen"
          />
        </div>
        <div>
          <h2>Responsive Web</h2>
          <p>
            As you can see through the screenshots on this page, a responsive
            design was a must. For example, if people who are visiting his
            LinkedIn want to check out his website, a desktop view is probably
            the way to go. But, if there are mobile users looking at his YouTube
            channel and want to see what other content he has through his
            website, then a mobile view is the right answer. Either way, the
            whole website is responsive for whatever screen size the user has.
          </p>
        </div>
      </div>
      <div class={`${style.section} ${style.sectionSm}`}>
        <h2>Current Status</h2>
        <p>
          The website is currently hosted and live! Check it out click{" "}
          <a
            href="https://www.commoncentsfw.com"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
      <img
        src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-banner.png"
        alt="CCFW Banner"
        class={style.CCFWBanner}
      />
      <div class={style.related}>
        <h3>Related Work</h3>
        <div class={style.cardsContainer}>
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("SquareOneJobs Web App")
              ]
            }
          />
          <Card
            card={
              cards[
                cards.map((c) => c.projTitle).indexOf("Brit & Nate Wedding")
              ]
            }
          />
        </div>
      </div>
    </div>
  );
};
export default CCFW;
