import { h } from "preact";

const WiresIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M395.636 232.727H116.364c-38.498 0-69.818-31.32-69.818-69.818s31.32-69.818 69.818-69.818h116.364v23.273c0 12.853 10.42 23.273 23.273 23.273h139.636c12.853 0 23.273-10.42 23.273-23.273V93.091h69.818c12.853 0 23.273-10.42 23.273-23.273s-10.42-23.273-23.273-23.273H418.91V23.273C418.909 10.42 408.489 0 395.636 0H256c-12.853 0-23.273 10.42-23.273 23.273v23.273H116.364C52.201 46.545 0 98.746 0 162.909s52.201 116.364 116.364 116.364h279.273c38.499 0 69.818 31.322 69.818 69.818 0 38.496-31.319 69.818-69.818 69.818H279.273v-23.273c0-12.853-10.42-23.273-23.273-23.273H116.364c-12.853 0-23.273 10.42-23.273 23.273v23.273H23.273C10.42 418.909 0 429.329 0 442.182s10.42 23.273 23.273 23.273h69.818v23.273c0 12.853 10.42 23.273 23.273 23.273H256c12.853 0 23.273-10.42 23.273-23.273v-23.273h116.364c64.163 0 116.364-52.201 116.364-116.364s-52.202-116.364-116.365-116.364z" />
  </svg>
);

export default WiresIcon;
