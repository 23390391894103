import { h } from "preact";
import style from "./style.css";
import { useTheme } from "../../hooks/useTheme";

const ThemeSwitch = () => {
  const { isDark, setIsDark } = useTheme();

  return (
    <div
      class={`${style.themeSwitch} ${isDark ? style.dark : style.light}`}
      onClick={() => setIsDark(!isDark)}
    >
      <div class={style.sun}>☀️</div>
      <div class={style.moon}>🌙</div>
    </div>
  );
};
export default ThemeSwitch;
