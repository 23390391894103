import { h } from "preact";

const DevicesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M411.429 442.514c0 5.554 4.503 10.057 10.057 10.057h80.457a10.061 10.061 0 0 0 10.058-10.058v-8.229H411.429v8.23zM501.943 269.714h-80.457c-5.554 0-10.057 4.503-10.057 10.057V288H512v-8.229c0-5.554-4.503-10.057-10.057-10.057zM210.286 333.714h182.857v-27.428H210.286zM420.571 86.857c0-15.148-12.28-27.429-27.429-27.429H118.857c-15.148 0-27.429 12.28-27.429 27.429v54.857h77.586c22.783.025 41.246 18.488 41.271 41.271V288h182.857v-8.229c.051-15.261 12.176-27.741 27.429-28.233V86.857zM210.286 352h91.429v36.571h-91.429zM411.429 306.286H512V416H411.429zM0 429.586c.01 12.69 10.295 22.975 22.985 22.985h146.03c12.69-.01 22.975-10.295 22.985-22.985v-4.443H0v4.443z" />
    <path d="M0 406.857h192V205.714H0zM169.015 160H22.985C10.295 160.01.01 170.295 0 182.985v4.443h192v-4.443c-.01-12.69-10.295-22.975-22.985-22.985zM347.429 406.857H210.286v18.286h146.286V416c-.001-5.049-4.094-9.143-9.143-9.143z" />
  </svg>
);

export default DevicesIcon;
