import { h } from "preact";

const HourglassIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M336.655 202.803c33.946-30.949 79.852-72.806 84.036-172.803H451V0H61v30h30.309c4.184 99.997 50.09 141.854 84.036 172.803C198.116 223.564 211 236.271 211 256s-12.884 32.436-35.655 53.197C141.399 340.146 95.493 382.003 91.309 482H61v30h390v-30h-30.309c-4.184-99.997-50.09-141.854-84.036-172.803C313.884 288.436 301 275.729 301 256s12.884-32.436 35.655-53.197zM241 368.438a75.843 75.843 0 0 0-13.677 4.195l-91.877 38.019c15.004-38.15 39.17-60.193 60.111-79.285C218.927 310.06 241 289.935 241 256v112.438zm75.443-37.071c20.941 19.092 45.106 41.135 60.111 79.284l-91.877-38.019A75.843 75.843 0 0 0 271 368.437V256c0 33.935 22.073 54.06 45.443 75.367zM134.919 100c-7.253-18.899-12.262-41.718-13.573-70h269.307c-1.311 28.282-6.319 51.101-13.573 70H134.919z" />
  </svg>
);

export default HourglassIcon;
