import { h } from "preact";

const MobileIcon = (props) => (
  <svg
    height="512pt"
    viewBox="-122 0 512 512"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M267.168 71.152V30.617C267.168 13.734 253.434 0 236.551 0H30.617C13.734 0 0 13.734 0 30.617v40.535zM0 440.848v40.535C0 498.266 13.734 512 30.617 512h205.934c16.883 0 30.617-13.734 30.617-30.617v-40.535zm157.613 49.964h-48.05c-8.368 0-15.149-6.78-15.149-15.148 0-8.363 6.781-15.148 15.149-15.148h48.05c8.367 0 15.149 6.785 15.149 15.148 0 8.367-6.782 15.149-15.149 15.149zM0 101.45h267.168v309.1H0zm0 0" />
  </svg>
);

export default MobileIcon;
