import { h } from "preact";
import { route } from "preact-router";
import style from "./style.css";

const Card = ({ card }) => {
  return (
    <div
      data-testid="card-container"
      class={`${style.card} ${style[card.projShortTitle]}`}
      onClick={() => route(card.route)}
    >
      {card.children}
      <div class={style.hoverData}>
        <div>
          <h2 data-testid="card-title">{card.projTitle}</h2>
          <p data-testid="card-role">{card.role}</p>
        </div>
      </div>
    </div>
  );
};
export default Card;
