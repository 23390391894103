import { h } from "preact";
import style from "./style.css";
import gs from "../general.css";
import Button from "../../../components/button";
import DevicesIcon from "../../../components/icons/DevicesIcon";
import QuestionIcon from "../../../components/icons/QuestionIcon";
import StarIcon from "../../../components/icons/StarIcon";
import WiresIcon from "../../../components/icons/WiresIcon";
import PuzzleIcon from "../../../components/icons/PuzzleIcon";
import ClipboardIcon from "../../../components/icons/ClipboardIcon";
import LocationIcon from "../../../components/icons/LocationIcon";
import GroupIcon from "../../../components/icons/GroupIcon";
import HourglassIcon from "../../../components/icons/HourglassIcon";

const Domov = () => {
  return (
    <div class={`${gs.project} ${style.domov}`}>
      <img
        class={gs.hero}
        src="https://nr-portfolio.s3.amazonaws.com/domov/hero.png"
        alt="Banner of Domov Logo, Web, and Mobile Mockups"
      />
      <div class={`${gs.projectDetailsSection} ${style.projectDetailsSection}`}>
        <div class={`${gs.projectDetailsInner} ${style.projectDetailsInner}`}>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>ROLE</h3>
            <h2>UX Designer</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>COMPANY</h3>
            <h2>Google UX Certificate</h2>
          </div>
          <div class={`${gs.projectDetail} ${style.projectDetail}`}>
            <h3>TIMEFRAME</h3>
            <h2>March 2022</h2>
          </div>
          <Button
            color="domovBlue"
            link="https://drive.google.com/file/d/1aKxj3QubK2nzNym-eQGWShenWTDmDfEI/view?usp=sharing"
            text="CASE STUDY"
            active={false}
          />
        </div>
      </div>
      <div class={gs.toolsUsedSection}>
        <h3>TOOLS & CONCEPTS</h3>
        <h2>Adobe XD · Mobile Design · Responsive Web Design</h2>
      </div>
      <div
        class={`${gs.section} ${gs.projectOverviewSection} ${style.projectOverviewSection}`}
      >
        <div class={gs.imgContainer}>
          <img
            class={gs.phone}
            src="https://nr-portfolio.s3.amazonaws.com/domov/home+-+mobile.png"
            alt="Domov App Home Screen"
          />
        </div>
        <div class={gs.right}>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <DevicesIcon />
            </div>
            <div>
              <h3>PRODUCT DESCRIPTION</h3>
              <h2>
                Domov is a service that helps users to have a easy, seamless
                apartment search. The typical user is between 18-30 who’s
                looking for an apartment during or after college.
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <QuestionIcon />
            </div>
            <div>
              <h3>PROBLEM</h3>
              <h2>
                Current apartment searching websites aren’t fully reliable and
                provide a sub-par user experience.
              </h2>
            </div>
          </div>
          <div class={gs.headerWithIcon}>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <StarIcon />
            </div>
            <div>
              <h3>PROJECT GOAL</h3>
              <h2>
                Develop a clean, easy to use apartment finding website that
                provides users with all of the up-to-date information that they
                need.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class={style.bgBlue}>
        <div
          class={`${gs.section} ${gs.section60} ${style.understandingSection}`}
        >
          <h1>Understanding the User</h1>
          <div class={style.split}>
            <div>
              <div class={style.personaHeader}>
                <img
                  src="https://nr-portfolio.s3.amazonaws.com/domov/jake.png"
                  alt="image of a user avatar"
                />
                <div>
                  <h3>PERSONA 1: JAKE EDWARDS</h3>
                  <p>
                    <b>Age:</b> 19
                  </p>
                  <p>
                    <b>Education:</b> Some College
                  </p>
                  <p>
                    <b>Occupation:</b> Student
                  </p>
                </div>
              </div>
              <h3>JAKES'S STORY</h3>
              <o>
                Jake Edwards is a college student who needs a website to find an
                affordable apartment because he’s moving out of his dorm and
                needs a place to stay.
              </o>
            </div>
            <div class={style.summary}>
              <h3>SUMMARY OF USER RESEARCH</h3>
              <p>
                In the beginning, I conducted user interviews and created
                empathy maps to try and understand the needs of the target
                users. I learned that users are tired of clunky,
                difficult-to-navigate apartment websites, and want something
                clean and easy to use.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Pain Points</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Navigation</h2>
            <p>
              Apartments websites are often difficult to use due to poor
              navigation
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Accessibility</h2>
            <p>
              Lots of text often times makes it difficult to navigate where to
              go on the apartment websites
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Experience</h2>
            <p>
              Current apartment websites often do not provide and engaging
              experience
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgBlue}>
        <div class={`${gs.section} ${gs.section30} ${style.sectionHeader}`}>
          <h1>Design Fun</h1>
          <p>
            After accessing our user’s needs, it’s time to start laying out
            wireframes and lo-fi prototypes. To start, I created a sitemap of
            the Information Architecture of the site.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/domov/sitemap.png"
          alt="Sitemap for Domov Website"
        />
        <p class={`${gs.caption} ${style.caption}`}>Sitemap</p>
      </div>
      <div class={`${gs.section} ${gs.section60}`}>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/domov/paper-wireframes.png"
          alt="Paper wireframe sketches for Domov Website and mobile app"
        />
        <p class={`${gs.caption} ${style.caption}`}>Paper Wireframes</p>
      </div>
      <div
        class={`${gs.section} ${gs.section60} ${style.digitalWireframesSection}`}
      >
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <WiresIcon />
          </div>
          <div>
            <h1>Digital Wireframes</h1>
            <p>A look at the digital wireframes for both web and mobile</p>
          </div>
        </div>
        <div class={style.imgContainer}>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/domov/digital-wireframes.png"
            alt="digital wireframe of domov website and mobile app"
          />
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.prototypeSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PuzzleIcon />
          </div>
          <div>
            <h1>Lo-Fi Prototype</h1>
            <p>
              Here’s a screenshot of the lo-fi prototype created in Figma. You
              can see the interactive model{" "}
              <a
                href="https://xd.adobe.com/view/bb1f8e78-06d3-463e-b3a1-f9674e321f8d-ee4c/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/domov/lofi-prototype.png"
          alt="lo-fi wireframe for website and mobile app"
        />
      </div>
      <div class={`${gs.section} ${gs.section40} ${gs.usabilityParameters}`}>
        <h1>Usability Study Parameters</h1>
        <div class={gs.parametersContainer}>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <ClipboardIcon />
            </div>
            <h2>Study Type</h2>
            <p>Unmoderated, usability study</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <LocationIcon />
            </div>
            <h2>Location</h2>
            <p>United States, Remote</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <GroupIcon />
            </div>
            <h2>Participants</h2>
            <p>5 participants</p>
          </div>
          <div>
            <div class={`${gs.iconContainer} ${style.iconContainer}`}>
              <HourglassIcon />
            </div>
            <h2>Length</h2>
            <p>10-15 minutes</p>
          </div>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Usability Study Findings</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <h2>Carousel</h2>
            <p>
              Carousel arrows need to be prominent so user knows there are more
              images
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <h2>Navbar</h2>
            <p>Buttons on navigation bar must be easy to point out</p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <h2>Listing Gestures</h2>
            <p>
              On mobile view, the action to go to the next listing in search
              must be well defined
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgBlue}>
        <div class={`${gs.section} ${gs.section30} ${style.sectionHeader}`}>
          <h1>Refining the Design</h1>
          <p>
            The usability tests helped to pinpoint the areas of the design that
            needed a little extra attention, and for good measure! Next, I
            refined the design and created the final hi-fi prototypes.
          </p>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section} ${style.mockupsSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <DevicesIcon />
          </div>
          <div>
            <h1>Mobile & Web Mockups</h1>
          </div>
        </div>
        <div class={style.imgsContainer}>
          <div class={style.row}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Home+-+Web.png"
              alt="Web Home Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Home+-+Mobile.png"
              alt="Mobile Home Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Search+-+Web.png"
              alt="Web Search Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Listing+-+Mobile.png"
              alt="Mobile Listing Screen"
            />
          </div>
          <div class={style.row}>
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Account+-+Mobile.png"
              alt="Mobile Account Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Listing+-+Web.png"
              alt="Web Listing Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Watchlist+-+Mobile.png"
              alt="Mobile Watchlist Screen"
            />
            <img
              src="https://nr-portfolio.s3.amazonaws.com/domov/mockups/Account+-+Web.png"
              alt="Web Account Screen"
            />
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section60} ${style.prototypeSection}`}>
        <div class={`${gs.headerWithIcon} ${style.headerWithIcon}`}>
          <div class={`${gs.iconContainer} ${style.iconContainer}`}>
            <PuzzleIcon />
          </div>
          <div>
            <h1>Hi-Fi Prototype</h1>
            <p>
              Here are screenshots of the hi-fi prototypes for the{" "}
              <a
                href="https://xd.adobe.com/view/48541fb7-24e2-4737-8c4a-6fcb345b2dea-1d07/?fullscreen"
                target="_blank"
                rel="noreferrer"
              >
                mobile
              </a>{" "}
              and{" "}
              <a
                href="https://xd.adobe.com/view/29f8acfc-e98a-4396-a0a4-b8ff6ca2bf9d-7cab/?fullscreen"
                target="_blank"
                rel="noreferrer"
              >
                web
              </a>{" "}
              apps created in AdobeXD .
            </p>
          </div>
        </div>
        <img
          src="https://nr-portfolio.s3.amazonaws.com/domov/hifi-prototype.png"
          alt="hi-fi wireframe for website and mobile app"
        />
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Accessibility Considerations</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <p>
              I used landmarks to help navigate the site for those who use
              assistive technologies
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <p>
              I used headings with different sized text to help the flow of the
              visual hierarchy
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <p>
              I picked out the primary color to have a high contrast, making it
              easier for those who are color blind to see
            </p>
          </div>
        </div>
      </div>
      <div class={style.bgBlue}>
        <div
          class={`${gs.section} ${gs.section30} ${style.goingForwardSection}`}
        >
          <h1>Going Forward</h1>
          <h3>IMPACT</h3>
          <p>
            Our users expressed their excitement to use Domov over other
            apartment finding websites. They thought it was more engaging and
            user-friendly.
          </p>
          <h3>WHAT I LEARNED</h3>
          <p>
            I have used Adobe XD in the past, but not to this extent. This
            project has fast-tracked my learning in how to use Adobe XD and all
            of it’s helpful tools. Grid Layouts were a big help while designing
            Domov.
          </p>
        </div>
      </div>
      <div
        class={`${gs.section} ${gs.painPointsSection} ${style.painPointsSection}`}
      >
        <h1>Next Steps</h1>
        <div class={gs.split}>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${gs.iconContainer} ${style.iconContainer}`}
            >
              1
            </div>
            <p>
              Conduct follow up research to see if design iterations indeed
              helped further the usability
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              2
            </div>
            <p>
              Continue to iterate and come up with intuitive features to better
              help users find the apartment they’re looking for
            </p>
          </div>
          <div class={gs.painPoint}>
            <div
              class={`${gs.iconContainer} ${style.iconContainer} ${style.iconContainer}`}
            >
              3
            </div>
            <p>Start diving deep and learning the in’s and out’s of Adobe XD</p>
          </div>
        </div>
      </div>
      <div class={`${gs.section} ${gs.section50} ${gs.wrapUpSection}`}>
        <h1>Thanks for reviewing my work on Domov!</h1>
        <p>I’d love to chat, my door’s always open.</p>
      </div>
    </div>
  );
};

export default Domov;
