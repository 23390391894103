import { h } from "preact";

const ResearchIcon = (props) => (
  <svg
    height={512}
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M182.31 409.231A42.693 42.693 0 0 0 141.755 380H75c-24.813 0-45-20.187-45-45V40H15C6.716 40 0 46.716 0 55v340c0 8.284 6.716 15 15 15h167.583c-.091-.256-.187-.51-.273-.769z" />
    <path d="M210 25.911A102.593 102.593 0 0 0 141.755 0H75c-8.284 0-15 6.716-15 15v320c0 8.284 6.716 15 15 15h66.755A72.643 72.643 0 0 1 210 397.579zM354.081 449.081c-25.375 0-49.231-9.882-67.175-27.825-17.943-17.942-27.825-41.799-27.825-67.175 0-25.375 9.882-49.231 27.825-67.175 37.041-37.041 97.309-37.041 134.35 0 17.943 17.943 27.825 41.8 27.825 67.175 0 25.376-9.882 49.232-27.825 67.175s-41.799 27.825-67.175 27.825zM450 273.926V55c0-8.284-6.716-15-15-15h-15v207.842a125.674 125.674 0 0 1 22.469 17.852 127.299 127.299 0 0 1 7.531 8.232z" />
    <path d="M354.081 229.126c12.348 0 24.417 1.778 35.919 5.204V15c0-8.284-6.716-15-15-15h-66.754A102.592 102.592 0 0 0 240 25.912V302.84c6.144-13.662 14.783-26.236 25.693-37.146 23.581-23.582 54.971-36.568 88.388-36.568zM501.765 452.268l-38.047-38.047a125.509 125.509 0 0 1-21.25 28.249 125.506 125.506 0 0 1-28.248 21.249l38.047 38.047c13.646 13.646 35.851 13.646 49.497 0 13.648-13.647 13.648-35.852.001-49.498z" />
  </svg>
);

export default ResearchIcon;
