import { h } from "preact";
import cardStyle from "../components/card/style.css";
import { useTheme } from "./useTheme";

const useCards = () => {
  const { isDark } = useTheme();

  return [
    {
      projType: "ux",
      projTitle: "SquareOneJobs",
      projShortTitle: "s1jDesignCard",
      role: "Lead UX Designer",
      route: "/work/squareonejobs",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogowhite.png"
            alt="SquareOneJobs logo"
            class={cardStyle.centerLogo}
          />
        </>
      ),
    },
    {
      projType: "ux",
      projTitle: "Thrive Fitness",
      projShortTitle: "thriveFitnessCard",
      role: "Lead UX Designer",
      route: "/work/thrive-fitness",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/thrive-fitness/thriveLogo.png"
            alt="Thrive Fitness logo"
            class={cardStyle.centerLogo}
          />
        </>
      ),
    },
    {
      projType: "ux",
      projTitle: "Moviegoers",
      projShortTitle: "moviegoersCard",
      role: "Lead UX Designer",
      route: "/work/moviegoers",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/moviegoers/logo.png"
            alt="Moviegoers logo"
            class={cardStyle.centerLogo}
          />
        </>
      ),
    },
    {
      projType: "ux",
      projTitle: "Domov",
      projShortTitle: "domovCard",
      role: "Lead UX Designer",
      route: "/work/domov",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/domov/logo.png"
            alt="Domov logo"
            class={cardStyle.centerLogo}
          />
        </>
      ),
    },
    {
      projType: "fe",
      projTitle: "SquareOneJobs Mobile App",
      projShortTitle: "s1jCard",
      role: "Designer & Developer",
      route: "/work/squareonejobs-mobile",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
            alt="SquareOneJobs logo"
            class={cardStyle.imgTopLeft}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jphone.png"
            alt="SquareOneJobs Mobile App"
            class={cardStyle.phone}
          />
        </>
      ),
    },
    {
      projType: "fe",
      projTitle: "SquareOneJobs Web App",
      projShortTitle: "s1jCard",
      role: "Designer & Developer",
      route: "/work/squareonejobs-web",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
            alt="SquareOneJobs logo"
            class={cardStyle.imgTopLeft}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-frontend/s1jlaptop.png"
            alt="SquareOneJobs Web App"
            class={cardStyle.laptop}
          />
        </>
      ),
    },
    {
      projType: "fe",
      projTitle: "CCFW",
      projShortTitle: "CCFWCard",
      role: "Designer & Developer",
      route: "/work/ccfw",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-logo.png"
            alt="CCFW logo"
            class={cardStyle.imgTopLeft}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-laptop-main.png"
            alt="CCFW Website Header"
            class={cardStyle.laptop}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/ccfw/CCFW-phone-main.png"
            alt="CCFW Website Header Mobile"
            class={cardStyle.phoneSide}
          />
        </>
      ),
    },
    {
      projType: "fe",
      projTitle: "Brit & Nate Wedding",
      projShortTitle: "BNWedding",
      role: "Designer & Developer",
      route: "/work/brit-nate-wedding",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/logo.png"
            alt="Brit & Nate Wedding Logo"
            class={cardStyle.imgTopLeft}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/header-laptop.png"
            alt="Brit & Nate Wedding Website Header"
            class={cardStyle.laptop}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/brit-nate-wedding/header-phone.png"
            alt="Brit & Nate Wedding Website Header Mobile"
            class={cardStyle.phoneSide}
          />
        </>
      ),
    },
    {
      projType: "be",
      projTitle: "SquareOneJobs Backend",
      projShortTitle: "s1jCard",
      role: "Designer & Developer",
      route: "/work/squareonejobs-backend",
      children: (
        <>
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-mobile/s1jlogo.png"
            alt="SquareOneJobs logo"
            class={cardStyle.imgTopLeft}
          />
          <img
            src="https://nr-portfolio.s3.amazonaws.com/s1j-backend/s1jdb.png"
            alt="Icon of a database"
            class={cardStyle.s1jDBLogo}
          />
        </>
      ),
    },
    {
      projType: "be",
      projTitle: "TraQ",
      projShortTitle: "traqCard",
      role: "Backend Designer & Developer",
      route: "/work/traq",
      children: (
        <>
          <img
            src={
              isDark
                ? "https://nr-portfolio.s3.amazonaws.com/traq/traqlight.png"
                : "https://nr-portfolio.s3.amazonaws.com/traq/traqdark.png"
            }
            alt="TraQ logo"
            class={cardStyle.traqLogo}
          />
        </>
      ),
    },
  ];
};

export default useCards;
