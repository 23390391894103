import { h } from "preact";

const LocationIcon = (props) => (
  <svg
    height={512}
    viewBox="0 0 24 24"
    width={512}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 0C7.038 0 3 4.066 3 9.065c0 7.103 8.154 14.437 8.501 14.745a.749.749 0 0 0 .998.001C12.846 23.502 21 16.168 21 9.065 21 4.066 16.962 0 12 0zm0 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z" />
  </svg>
);

export default LocationIcon;
